@import 'variables';
@import 'animation';
@import 'mixin';
@import 'sidebar-themes';

.page-wrapper {
  height: auto;
  cursor: default;
  .page-content {
    display: inline-block;
    width: 100%;
    transition: padding-left .3s ease;
    overflow-x: hidden;

    .overlay {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 998;
      background: #000;
      opacity: .5;
      display: block;


    }
  }

  &.toggled {

    .sidebar-wrapper {
      left: 0px;
    }

    .page-content {
      @media screen and (min-width: 768px) {
        padding-left: $sidebar-width;
      }

      .overlay {

        @media screen and (max-width: 768px) {
          display: block;
        }
      }
    }
    &.pinned{
      .page-content {

        @media screen and (min-width: 768px) {
          padding-left: $sidebar-pinned-width;
        }
      }
    }

  }

  &.pinned:not(.sidebar-hovered) {
    .sidebar-wrapper {
      width: $sidebar-pinned-width;
      .sidebar-menu .header-menu,
      .sidebar-menu .sidebar-submenu,
      .sidebar-menu ul>li>a>span,
      .sidebar-menu ul>li>a::after,
      .sidebar-footer>div:not(.pinned-footer) {
        display: none!important;
        
      }
    }
  }

}
.border-radius-on {

  .badge {
    border-radius: 8px;
  }

  .sidebar-menu ul li i {
    border-radius: $border-radius;
  }
}
.side_opened {
  transition: margin-left .3s ease, width .3s ease;
  margin-left: $sidebar-pinned-width;
}
div.side_closed.container-fluid {
  transition: margin-left .3s ease, width .3s ease;
  padding-left: $sidebar-width !important;
}