.secretaria-multi-select-list-item-search-container {
    width: 100%;
    height: auto;
    position: relative;
}

.secretaria-multi-select-list-item-search-container input {
    padding-right: 45px;
}

.secretaria-multi-select-list-item-search-container .secretaria-multi-select-list-item-search-container-icon {
    position: absolute;
    right: 10px;
    top: calc(50% + 12px);
    transform: translateY(-50%);
}

.secretaria-multi-select-list-item-search-container .secretaria-multi-select-list-item-search-container-icon:hover {
    cursor: pointer;
    filter: brightness(0.8);
}

.secretaria-multi-select-list-item-search-container .secretaria-multi-select-list-item-search-container-icon:focus {
    outline: 1px solid rgba(0, 0, 0, 0.74);
    border-radius: 5px;
}