.declaracao-component .declaracao-container .texto-separado{
    display: flex;
    justify-content: space-between;
}
.declaracao-component .declaracao-container .texto-com-underline {
    position: relative;
    width: 100%;
    display: inline-flex;
    margin: 5vh 0 5vh;
}
.declaracao-component .declaracao-container .texto-com-underline .half-underline{
    position: relative;
    bottom: -4px;
    left: 0;
    width: 40%;
}