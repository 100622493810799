.declaracao-component {
    overflow: auto;
}

.declaracao-container {
    width: 650px;
    height: 1000px;
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    margin: 0 auto;
}

.declaracao-container p {
    text-align: left;
    margin-bottom: 0.5rem;
}

.declaracao-container .titulo {
    font-weight: 400;
}

.declaracao-container .subtitulo {
    justify-content: center;
}

.declaracao-container h4 {
    margin: 5vh 0;
}

.declaracao-container .texto-variavel-paragrafo {
    text-align: left;
    margin-bottom: 2vh;
    display: flex;
    flex-wrap: wrap;
}

.declaracao-container .texto-variavel-paragrafo div {
    font-weight: bold;
}

.texto-com-negrito {
    font-weight: bold;
}

.declaracao-container .declaracao-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 6vh;
    text-align: left;
}

.declaracao-container .declaracao-footer div {
    width: 40%;
}

.declaracao-container .declaracao-footer div div {
    width: unset;
}

.declaracao-container .declaracao-footer .legenda-footer p {
    margin: 0;
}

.declaracao-container .bold-text {
    font-weight: bold;
}

.print-content {
    display: flex;
    justify-content: flex-end;
}

.print-button {
    margin: 2% 2% 0 0;
    width: 25%;
    margin-bottom: 3%;
    padding: 0.5rem 1rem;
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.print-button:hover {
    background-color: var(--primary-color-background);
}

@media print {
    body {
        visibility: hidden;
    }

    @page {
        margin: 0;
    }

    .declaracao-container {
        visibility: visible !important;
        position: fixed;
        height: 90%;
        width: 90%;
        top: 0;
        left: 0;
        margin: 5%;
    }

    .declaracao-container hr {
        border-top: solid 2px #000 !important;
    }
    .declaracao-container h4 {
        margin: 3vh 0 3vh 0;
    }
}