.seb-secretaria-component-replay-parcial-regular {

}

.seb-secretaria-component-replay-parcial-regular .filtros {
    background-color: #F0F0F0;
    padding: 10px;
    border-radius: 5px;
    margin: 10px 0;
}

.seb-secretaria-component-replay-parcial-regular .filtros button {
    width: auto;
}

.seb-secretaria-component-replay-parcial-regular .row-modal-financeiro.trUnsaved {
    border: 2px solid #0cb400;
}

.seb-secretaria-component-replay-parcial-regular .table-responsive .tableStyle.table thead tr th {
    padding: 25px 15px;
    position: relative;
}

.seb-secretaria-component-replay-parcial-regular th .badge-editable {
    position: absolute;
    right: 4px;
    top: 4px;
    opacity: .8;
}
