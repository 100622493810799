$sidebar-width:240px;
$sidebar-pinned-width:50px;
$border-radius:10px;
$sidebar-bg-color: rgb(41, 223, 255);
$sidebar-bg-colorfinal: rgb(12, 166, 194);
$primary-color: #ffffff;
$primary-highlight-color: #d8d8d8;
$border-color: #1A69B0;
$submenu-bg-color: #1A69B0;
$menu-icon-bg-color: #1A69B0;
$menu-icon-color: #FAFAFA;
$menu-icon-highlight-color: #007BFF;
$menu-header-color: #868686;
$footer-bg-color: #1A69B0;
$shadow-color: #348FE2;
$scrollbar-color: #525965;