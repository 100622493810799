.static-Action{
    background-color: var(--primary-color-disable) !important;
    border-bottom: 1px solid #003c67;
    border-left: 1px solid #003c67;
    right: 0;
    position: -webkit-sticky;
    position: sticky;
    z-index: 3;
}
.static{
    background-color: rgb(223, 223,223) !important;
    border-bottom: 1px solid #003c67;
    border-left: 1px solid #003c67;
    right: 0;
    position: -webkit-sticky;
    position: sticky;
}
.seb-secretaria-component-editable-td {
    height: 100%;
}

.seb-secretaria-component-editable-td.editable {
    padding: 0;
    background-color: white;
}

.seb-secretaria-component-editable-td.editable .seb-secretaria-component-editable-td-input {
    display: block;
    height: 100%;
    min-height: 40px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: none;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
}
.tableStyle {
    height: 100%;
} 
.tableStyle td,
.tableStyle td input {
    height: 100%;
    text-align: center;
    vertical-align: middle;
    text-overflow: ellipsis;
    overflow: hidden;
}

.tableStyle td:last-child {
    overflow: visible;
}

.tableStyle td input {
    border: none;
}
.seb-secretaria-component-editable-td.editable .seb-secretaria-component-editable-td-input:focus {
    outline: none;
    border: 1px solid #0ca6c2;
}

.tableStyle .hideDetails {
    display: none;
}

.tableStyle .tableDetails .table-responsive {
    padding-bottom: 0;
    min-height: 0;
}
.tableStyle .tableDetails .p-10{
    border-radius: 20px;
    background-color: #FFFFFF;
}

.tableStyle .tableDetails thead{
    position: relative;
    z-index: 0;
}