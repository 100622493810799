.interval-bar {
    width: 400px;
    height: 50px;
    background-color: #f0f0f0;
    position: relative;
  }
  
  .visualInterval {
    height: 100%;
    background-color: #007bff;
    position: absolute;
    top: 0;
    opacity: 0.75;
    z-index: 2;
  }
  
  .visualInterval .intervalToolTip {
    height: 100%;
  }