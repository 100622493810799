.show {
    opacity: 1;
}

.modal-90w{
    min-width:90%;
}
/****** HOME ******/
.welcome h1 {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.5px;
    color: #11151F;
}

.welcome h2 {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: #424E66;
}

.section .welcome {
    margin-top: 79px;
    margin-bottom: 36px;
}

.section .welcome-progress {
    margin-top: 58px;
    margin-bottom: 48px;
}

.welcome-progress .progress:after {
    border: 5px solid #FFFFFF;
}

.welcome-progress .progress .progress-value {
    background: #EBEFF5;
}

.cards-matricula_Modal {
    width: 100%;
    background: none !important;
    box-shadow: none !important;
    border-radius: 0px;
    padding: 0px !important;
    text-align: center;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    box-shadow: none !important;
}

.cards-matricula {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    padding: 44px 24px 24px;
    text-align: center;
    margin-bottom: 45px;
    box-shadow: 0px 10px 14px rgba(0, 0, 0, 0.16);
}

    .cards-matricula .cards-header {
        display: flex;
        justify-content: center;
        margin-bottom: 35px;
    }

    .cards-matricula .siglas {
        position: relative;
        width: 130px !important;
        height: 130px !important;
        min-width: 130px !important;
        float: left;
        background: linear-gradient(180deg, #B8C6D9 0%, #9FACBE 100%);
        font-weight: 500;
        font-size: 24px;
        letter-spacing: -0.5px;
        color: #FFFFFF;
        text-align: center;
        line-height: 130px !important;
        border-radius: 50% !important;
        text-transform: uppercase;
    }

.cards-header .siglas img {
    width: 130px;
    height: 130px;
    border-radius: 100%;
    object-fit: cover;
    object-position: center;
}

.cards-matricula .siglas .check-success {
    width: 30px !important;
    height: 30px !important;
    position: absolute !important;
    bottom: 0 !important;
    right: 5px !important;
    background: #2E9963 !important;
    border: 2px solid #FFFFFF !important;
    border-radius: 50px !important;
}

    .cards-matricula .siglas .check-success .seb-check {
        position: absolute !important;
        width: 10px !important;
        top: 9px !important;
        right: 0 !important;
        left: 0 !important;
        margin: 0 auto !important;
    }

.cards-matricula .siglas .check-danger {
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: 0;
    right: 5px;
    background: #FFA526;
    border: 2px solid #FFFFFF;
    border-radius: 50px;
}

    .cards-matricula .siglas .check-danger .seb-check {
        position: absolute;
        width: 2px;
        top: 8px;
        right: 0;
        left: 0;
        margin: 0 auto;
    }

.cards-matricula .dados {
    float: left;
    margin-left: 30px;
    max-width: 300px;
    text-align: left;
}

    .cards-matricula .dados h3 {
        text-align: left;
        text-transform: capitalize;
    }

        .cards-matricula .dados h3 a {
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            text-align: left;
            letter-spacing: -0.5px;
            color: #11151F;
            margin-bottom: 8px;
            transition: .3s;
        }

            .cards-matricula .dados h3 a:hover {
                color: #0068b3;
            }

            .cards-matricula .dados h3 a svg {
                margin-left: 7px;
            }

    .cards-matricula .dados p {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.2px;
        color: #738399;
        margin-bottom: 0;
        text-align: left;
    }

    .cards-matricula .dados div:not(.msg-matriculado) {
        min-height: 60px;
        text-transform: capitalize;
    }

.cards-matricula a {
    text-decoration: none;
}

.cards-matricula .medium {
    padding: 12px 8px;
}

.cards-matricula .flex-fill, .cards-matricula .flex-sm-fill {
    margin-left: 4px;
    margin-right: 4px;
    font-size: 14px;
}

.cards-footer .p-2 {
    border: 0.5px solid #738399;
    border-radius: 4px;
    margin-right: 16px;
    cursor: pointer;
    flex: 1;
    padding: 10px !important;
    max-width: 96px;
    background: #FFFFFF;
    transition: .3s;
}

    .cards-footer .p-2.cards-primary {
        background: #0068B3;
        border: 0.5px solid #0068B3;
    }

        .cards-footer .p-2.cards-primary p {
            color: #FFFFFF;
        }

    .cards-footer .p-2:hover {
        background: #0068B3;
        border: 0.5px solid #0068B3;
    }

        .cards-footer .p-2:hover p, .cards-footer .p-2:hover svg path {
            color: #FFFFFF;
            stroke: #FFFFFF;
        }

    .cards-footer .p-2:last-child {
        margin-right: 0;
    }

    .cards-footer .p-2 p {
        font-family: "Inter", Arial;
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        letter-spacing: -0.2px;
        color: #738399;
        margin-top: 10px;
        min-height: 40px;
        display: inline-flex;
        align-items: center;
        vertical-align: middle;
        line-height: normal;
        margin: auto;
    }

.cards-footer .cards-primary.student {
    background: #FFFFFF;
}

    .cards-footer .cards-primary.student svg path {
        stroke: #0068B3;
    }

    .cards-footer .cards-primary.student p {
        color: #0068B3;
    }

    .cards-footer .cards-primary.student:hover {
        background: #0068B3;
    }

        .cards-footer .cards-primary.student:hover svg path {
            stroke: #FFFFFF;
        }

        .cards-footer .cards-primary.student:hover p {
            color: #FFFFFF;
        }

.btn-primary.btn-home {
    max-width: 450px;
    background: #FFFFFF;
    border: 0.5px solid #0068B3;
    border-radius: 4px;
    color: #0068B3;
    transition: .3s;
}

    .btn-primary.btn-home:hover {
        background: #0068B3;
        color: #FFFFFF;
        stroke: #FFFFFF
    }

        .btn-primary.btn-home:hover svg path {
            stroke: #FFFFFF
        }

.links-portais {
    margin: 80px 0;
}

    .links-portais a {
        background: #0068B3;
        border-radius: 4px;
        display: flex;
        transition: .3s;
    }

        .links-portais a img {
            width: 200px;
            height: 80px;
            object-fit: cover;
            border-radius: 4px;
            object-position: center;
        }

        .links-portais a span {
            font-family: "Inter", Arial;
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            letter-spacing: -0.5px;
            color: #FFFFFF;
            margin: auto;
            margin-left: 24px;
            text-decoration: none;
        }

        .links-portais a:hover {
            background: #004A7F;
            text-decoration: none;
        }

        .links-portais a svg {
            margin: auto;
            margin-right: 24px;
        }

.msg-matriculado {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    color: #22734B;
    background: #EBFFF5;
    border: 1px solid #CEF2E0;
    border-radius: 4px;
    padding: 4px 8px;
    display: inline-block;
    vertical-align: sub;
    margin-top: 5px;
}

    .msg-matriculado.msg-danger {
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.2px;
        text-transform: uppercase;
        color: #CC5500;
        background: #FFF8EB;
        border: 1px solid #FEEBCB;
        border-radius: 4px;
        padding: 4px 8px;
        display: inline-block;
        vertical-align: sub;
        margin-top: 5px;
    }

/****** MODAL HOME ******/
.modal-home .modal-header {
    padding: 30px 40px 8px;
}

.modal-home button.close span {
    font-size: 25px;
    font-weight: 400;
}

.modal-home button.close:focus {
    outline: none !important;
}

.table-modal {
    border-collapse: separate;
    border-spacing: 0 16px;
}

    .table-modal thead tr th {
        font-family: "Inter", Arial;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.2px;
        text-transform: uppercase;
        color: #738399;
        border-top: 0;
        border-bottom: 0;
    }

        .table-modal thead tr th:first-child {
            padding-left: 40px
        }

        .table-modal thead tr th:last-child {
            padding-right: 40px
        }

    .table-modal tbody tr td {
        font-family: "Inter", Arial;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.2px;
        color: #11151F;
        border-top: 0;
        background-color: #F0F9FF;
        vertical-align: middle;
    }

        .table-modal tbody tr td:first-child {
            padding-left: 40px;
        }

        .table-modal tbody tr td:last-child {
            padding-right: 40px
        }

.modal-home .modal-body {
    padding: 0;
}

.btn-acoes, .btn-acoes:focus {
    border: 0;
    background-color: transparent;
    outline: none;
    padding: 0 6px 0 0;
}

    .btn-acoes.btn-view:before {
        content: '';
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        display: inline-block;
        width: 20px;
        height: 20px;
    }

    .btn-acoes.btn-download:before {
        content: '';
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        display: inline-block;
        width: 20px;
        height: 20px;
    }

    .btn-acoes.btn-view:disabled:before {
        content: '';
    }

    .btn-acoes.btn-download:disabled:before {
        content: '';
    }

    .btn-acoes:disabled {
        cursor: not-allowed
    }

.modal-home .modal-footer {
    background: transparent;
}

.modal-home .msg-danger {
    display: flex;
    justify-content: left;
    flex: 1;
}

    .modal-home .msg-danger span {
        font-family: "Inter", Arial;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        color: #DC393A;
    }

.modal-midia .modal-footer {
    background: transparent;
}

.modal-midia iframe {
    width: 100%;
    height: 737px;
}

.modal-midia .modal-body {
    padding: 0;
}

.modal-midia button.close span {
    font-size: 25px;
    font-weight: 400;
}

.modal-midia button.close span {
    outline: none !important;
}

.modal-servico .nav-link {
    background: #D1ECFF;
    border-radius: 0;
    font-family: "Inter", Arial;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: #738399;
}

    .modal-servico .nav-link.active {
        color: #0068B3;
        border-color: transparent;
        border-radius: 4px 4px 0 0;
    }

.modal-servico .nav-tabs {
    border: 0;
}

.modal-servico .modal-body {
    padding: 0;
}

.modal-servico .nav-tabs {
    overflow-x: auto;
    overflow-y: hidden;
    display: -webkit-box;
    display: -moz-box;
    flex-wrap: unset;
    background: #D1ECFF;
    border-radius: 4px 4px 0 0;
    margin-left: 16px;
    margin-right: 16px;
}

    .modal-servico .nav-tabs > li {
        float: none;
    }

    .modal-servico .nav-tabs::-webkit-scrollbar {
        height: 5px
    }

    .modal-servico .nav-tabs::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    .modal-servico .nav-tabs::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 10px;
    }

        .modal-servico .nav-tabs::-webkit-scrollbar-thumb:hover {
            background: #555;
        }

.modal-servico .tab-content {
    background-color: #FFFFFF;
}

.modal-servico .servicoComplementar {
    background-color: #F5F7FA;
}
