.interval-bar {
  width: 400px;
  height: 50px;
  background-color: #f0f0f0;
  position: relative;
}

.interval {
  height: 100%;
  background-color: #007bff;
  position: absolute;
  top: 0;
  cursor: move;
  opacity: 0.75;
  z-index: 2;
}

.interval .intervalToolTip {
  height: 100%;
}