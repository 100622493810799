.tooltip-container {
    position: relative;
  }
  
  .tooltip-text {
    visibility: hidden;
    max-width: 100%;
    background-color: #333;
    font-size: 0.8rem;
    color: #FFF;
    text-align: center;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 9999;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
    word-wrap: break-word;
  }
  
  .tooltip-container:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }