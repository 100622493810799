@mixin themes ($sidebar-bg-color, $primary-color, $primary-highlight-color, $border-color, $submenu-bg-color, $menu-icon-bg-color, $menu-icon-color, $menu-icon-highlight-color, $menu-header-color, $footer-bg-color, $shadow-color, $scrollbar-color, $sidebar-bg-colorfinal) {
    .sidebar-wrapper {
        background: linear-gradient(180deg, var(--primary-color-background) 0%, var(--primary-color-disable) 100%);

        >div {
            height: 100vh;
        }

        .sidebar-item {
            border-top: 1px solid $border-color;
            cursor: pointer;

            &:first-child {
                border-top: none;
            }
        }

        a:not(.dropdown-item),
        .dropdown-button,
        .sidebar-header {
            color: $primary-color;
        }

        a:not(.dropdown-item):hover,
        .dropdown-button:hover,
        .sidebar-header>div.dropdown:hover,
        .sidebar-menu li.active>a {
            color: #808080;
        }

        .sidebar-menu li.active>a {
            font-weight: bold;
            border-radius: 8px 0px 0px 8px;
            background-color: white;
            color: var(--primary-color);
        }

        .sidebar-menu a:hover i,
        .sidebar-menu li.active>a i {
            color: $menu-icon-highlight-color;
        }

        .sidebar-menu ul li a i {
            //background-color: $menu-icon-bg-color;
        }


        /*.sidebar-menu .sidebar-dropdown div {
            background-color: $submenu-bg-color;
        }*/

        .sidebar-menu .header-menu span {
            color: $menu-header-color;
        }

        .sidebar-menu .sidebar-dropdown .sidebar-submenu ul li:hover{
            a {
                color: #aaa !important;
                transition: font-weight .3s ease;
                font-weight: 600;
            }
        }
    }

    &.toggled #close-sidebar {
        color: $primary-color;
    }

    &.toggled #close-sidebar:hover {
        color: $primary-highlight-color;
    }
}


// Links, buttons, and more within the dropdown menu
//
// `<button>`-specific styles are denoted with `// For <button>s`
.dropdown-item {
    color: black;

    &:hover,
    &:focus {
        color: black !important;
        background-color: var(--primary-color);
        border-radius: 8px;
    }

    &.active,
    &:active {
        color: yellow;
    }

    &.disabled,
    &:disabled {
        color: black;
        pointer-events: none;
        background-color: #808080;
    }
}

.dropDownItemSemBorda {
    color: black;

    &:hover,
    &:focus {
        color: black !important;
        background-color: #E0E0E0;
        border-radius: 8px;
    }

    &.active,
    &:active {
        color: yellow;
    }

    &.disabled,
    &:disabled {
        color: black;
        pointer-events: none;
        background-color: #808080;
    }
}

// Dropdown text
.dropdown-item-text {
    color: green;
}

.nav-link {
    color: var(--primary-color) !important;
}