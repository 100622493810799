.seb-secretaria-component-modal-financeiro {
    
}

.seb-secretaria-component-modal-financeiro .filtros {
    background-color: #F0F0F0;
    padding: 10px;
    border-radius: 5px;
    margin: 10px 0;
}

.seb-secretaria-component-modal-financeiro .filtros button {
    width: auto;
}

.seb-secretaria-component-modal-financeiro .row-modal-financeiro.trUnsaved {
    border: 2px solid #0cb400;
}

.seb-secretaria-component-modal-financeiro .table-responsive .tableStyle.table thead tr th {
    padding: 25px 15px;
    position: relative;
}

.seb-secretaria-component-modal-financeiro th .badge-editable {
    position: absolute;
    right: 4px;
    top: 4px;
    opacity: .8;
}