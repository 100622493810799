.tip{
    position: relative;
}
.tooltip-headbar{
    display: none;
    position: absolute;
    z-index: 2;
    margin-left: 50px;
    padding: 8px;
    top: 40px;
    right: -80px;
    background-color: #e8eaef;
    font-size: 0.8rem;
    text-align: center;
    border-radius: 6px;
    align-items: center;
    padding: 4px;
}
.tip:hover .tooltip-headbar{
    display: flex;
    opacity: 1;
}
.box-tip div{
    margin: 0;
}
@media (max-width: 768px) {
    .tooltip-headbar{
        display: none;
    }
    
}