.error-message-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .error-icon {
    font-size: 60px;
    margin-bottom: 16px;
  }
  
  .error-title {
    font-size: 32px;
    color: red;
    margin-bottom: 16px;
    text-align: center;
  }

  .info-title {
    font-size: 32px;
    color: #06AED4;
    margin-bottom: 16px;
    text-align: center;
  }
  
  .error-message {
    color: black;
    font-size: 18px;
  }