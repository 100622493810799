.seb-secretaria-modal-contrato .contrato-planosTitulo{
    text-align: center;
    width: 100%;
    display: flex;
    color: black;
    justify-content: center;
    font-size: 1.5rem
}
.seb-secretaria-modal-contrato .contrato-warningText{
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 1.3rem;
    margin-bottom: 25px;
    color: red;
    font-weight: bold
}
.seb-secretaria-modal-contrato .contrato-inputField{
    border: solid 2px #28a745
}
.seb-secretaria-modal-contrato .contrato-inputField a{
    padding: 0;
}
.seb-secretaria-modal-contrato .default-value option{
    font-style: normal;
    color: #212529;
}
.seb-secretaria-modal-contrato .default-value{
    font-style: italic !important;
    font-weight: 600;
    color: green !important;
}
.seb-secretaria-modal-contrato .contrato-inputFieldError{
    border: solid 2px red
}
.seb-secretaria-iframe-container iframe{
    width: 100%;
    height: 400px;
}