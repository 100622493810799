/*
CSS páginas
*/
:root {
    --primary-color: #0068b3;
    --primary-color-onBack: #0068b3;
    --primary-color-drop: #0077cd;
    --primary-color-disable: #004a80;
    --primary-color-background: #003c67;
    --primary-color-selecionado: #0086e6;
}
body {
    background-color: white;
    font-size: 12px;
    font-family: 'Open Sans',"Helvetica Neue",Helvetica,Arial,sans-serif;
    color: #707478
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
    background-color: var(--primary-color-disable)
}
.ddlUnidade{
    padding-top: 10px;
    padding-left:30px;
}
.tituloCss {
    margin-left: 25px;
}


h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
    color: #242a30;
    font-family: Segoe UI;
}

    h1 small, h2 small, h3 small, h4 small, h5 small, h6 small {
        font-size: 60%;
        font-weight: 300;
        color: #7c7f83
    }

a {
    transition: color 100ms ease-in-out;
    -o-transition: color 100ms ease-in-out;
    -ms-transition: color 100ms ease-in-out;
    -moz-transition: color 100ms ease-in-out;
    -webkit-transition: color 100ms ease-in-out
}

    a:focus {
        outline: 0
    }

label {

}

.page-header-fixed {
    padding-top: 54px
}

.page-without-sidebar .content {
    margin-left: 0
}

.page-with-right-sidebar .sidebar, .page-with-right-sidebar .sidebar-bg {
    left: auto;
    right: 0
}

.page-sidebar-fixed .sidebar {
    position: fixed
}

.page-with-right-sidebar .content {
    margin-left: 0;
    margin-right: 220px
}

.page-with-right-sidebar .footer {
    margin-left: 25px;
    margin-right: 245px
}

.page-with-two-sidebar.page-sidebar-fixed .sidebar.sidebar-right {
    position: fixed
}

.page-with-two-sidebar .sidebar-bg.sidebar-right, .page-with-two-sidebar .sidebar.sidebar-right {
    right: 0;
    left: auto
}

.page-with-two-sidebar .content {
    margin-right: 220px
}

.page-with-two-sidebar.page-sidebar-minified .sidebar-bg.sidebar-right, .page-with-two-sidebar.page-sidebar-minified .sidebar.sidebar-right {
    width: 220px
}

.page-with-two-sidebar.page-right-sidebar-collapsed .sidebar-bg.sidebar-right, .page-with-two-sidebar.page-right-sidebar-collapsed .sidebar.sidebar-right {
    right: -220px
}

.page-with-two-sidebar.page-right-sidebar-collapsed .content {
    margin-right: 0
}

#page-loader {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #E6E8EC
}

    #page-loader.fade {
        display: none
    }

        #page-loader.fade.in {
            display: block
        }

/*css secretaria inicio}*/

.FundoTopo {
    background-color: var(--primary-color-background);
    color: #ffffff;
    border-radius: 5px;
}

    .FundoTopo a {
        background-color: var(--primary-color-background);
        color: #ffffff !important;
        border-radius: 5px;
    }

.Fundounidade :hover {
    background-color: #ffffff;
    color: var(--primary-color) !important;
    border-radius: 8px;
}

.dropdown-menu {
    background-color: white !important;
}

.ajusteLogo {
    max-height: 65px;
    margin-top: -15px !important;
}

.AjustePagina {
    width: 100% !important;
    /*margin:15px;
    padding-top:55px;*/
}

.border {
    border-color:#000000 !important;
    border:solid 15px !important;
}
/*css secretaria fim}*/
@-webkit-keyframes rotation {
    from {
        -webkit-transform: rotate(0deg)
    }

    to {
        -webkit-transform: rotate(359deg)
    }
}

@-moz-keyframes rotation {
    from {
        -moz-transform: rotate(0deg)
    }

    to {
        -moz-transform: rotate(359deg)
    }
}
/*@-o-keyframes rotation{
	from{-o-transform:rotate(0deg)}
	to{-o-transform:rotate(359deg)}
}*/
@keyframes rotation {
    from {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(359deg)
    }
}

/* Inicio CSS Manager  */
.selectTop {
    background-color: #3D4750;
    width: 100%;
    background-color: #3D4750;
    border: 0px;
    padding: 5px 1px 5px 5px;
}

.divUnidadeTop {
    margin: 14px 0px 0px 5px;
    color: #FFF;
}

.DivDataTop {
    color: #FFF;
    padding: 18px 0px 0px 0px;
    text-align: center
}

.divLogo_Bot {
    padding: 15px 30px 0px 15px;
    float: left
}

.divLogo {
    padding: 0px 0px 0px 0px;
    float: left;
    color: white;
}

    .divLogo h4 {
        color: #FFF;
        width: 152px;
        height: 20px;
        margin-top: 3px;
    }

._Bot {
    margin: -15px -32px 0px 0px;
    float: left;
}

.Bordas {
    font-size: 14px !important;
    border-radius: 3px !important;
    overflow: hidden !important;
    display: table !important;
    width: 100% !important
}

    .Bordas li:after, .Bordas li:before {
        border: none !important
    }

    .Bordas li {
        float: none !important;
        display: table-cell !important;
        padding: 15px !important;
        line-height: 20px !important;
        background: #f0f3f4 !important;
        -webkit-border-radius: 0 !important;
        -moz-border-radius: 0 !important;
        border-radius: 0 !important
    }

        .Bordas li:focus, .Bordas li:hover {
            background: #d3dadf !important
        }

    .Bordas.clickable li:focus:not(.active) a, .Bordas.clickable li:hover:not(.active) a {
        color: #333 !important
    }

    .Bordas li small {
        font-size: 11px !important;
        color: #999 !important;
        display: block !important;
        line-height: 14px !important
    }

    .Bordas li a {
        margin-left: 30px !important;
        display: block !important;
        text-decoration: none !important
    }

    .Bordas li.active small {
        color: #b2e6e6 !important
    }

    .Bordas .label {
        float: left !important;
        padding: 0 !important;
        height: 20px !important;
        width: 20px !important;
        line-height: 20px !important;
        text-align: center !important;
        margin-right: 10px !important;
        top: 0 !important;
        background: #6d7479 !important;
        -webkit-border-radius: 50% !important;
        -moz-border-radius: 50% !important;
        border-radius: 50% !important
    }

    .Bordas li.active, .Bordas li.active:focus, .Bordas li.active:hover {
        background: #00acac !important
    }

        .Bordas li.active .label {
            background: #008181 !important
        }

.amarelo {
    color: #F5C90D
}

.inicio-menu {
    position: static;
    z-index: 999
}

.BordasPaginas {
    border: #E3E3E3 solid 1px;
    border-radius: 4px;
    padding: 10px 10px 10px 10px
}

.tableModal {
    width: 99%;
    border: #E3E3E3 solid 2px;
    border-radius: 4px;
    text-align: center;
    padding: 5px 5px 5px 5px;
    margin: 0px 5px 0px 5px
}

    .tableModal th {
        border: #E3E3E3 solid 2px;
        text-align: center;
        padding: 5px 5px 5px 5px;
        font-weight: bold
    }

    .tableModal td {
        border: #E3E3E3 solid 2px;
        text-align: center;
        padding: 5px 5px 5px 5px;
    }

.field-validation-error {
    text-align: center;
    margin: 5px 5px 5px 5px;
    padding: 5px 5px 5px 5px;
    color: #F99C0F;
}

.validation-summary-errors {
    margin: 5px 5px 5px 5px;
    padding: 5px 5px 5px 5px;
    color: #F99C0F;
    font-size: 14px
}

.BotAcoes {
    padding: 8px 10px 8px 10px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    -moz-box-shadow: 3px 3px 3px #999;
    -webkit-box-shadow: 3px 3px 3px #999;
    box-shadow: 3px 3px 3px #999;
}


.field-validation-valid {
    border: solid 1px #F99C0F;
}


.input-validation-error {
    border: solid 1px #F99C0F;
}

.input-validation-valid {
    border: solid 1px #F99C0F;
}


/* Fim CSS Manager  */

.spinner, .spinner-small {
    height: 40px;
    width: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -20px -20px 0 0;
    border: 2px solid #fff;
    border-top: 2px solid #24B498;
    border-radius: 100%
}

.fade.in .spinner, .fade.in .spinner-small {
    -webkit-animation: rotation .6s infinite linear;
    -moz-animation: rotation .6s infinite linear;
    -o-animation: rotation .6s infinite linear;
    animation: rotation .6s infinite linear
}

.spinner-small {
    border: 2px solid rgba(0,0,0,.05);
    border-top: 2px solid #24B498;
    height: 30px;
    width: 30px;
    margin: -15px -15px 0 0
}

.header {
    z-index: 1020;
    margin-bottom: 0;
    background-color:white;
    display: flex;
    justify-content: space-between;
    margin-right: 85px;
    padding-bottom:50px;
}

.header .user-message *{
    font-weight: 500;
}

.navbar {
    border: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: 0 0 2px rgba(0,0,0,.3);
    box-shadow: 0 0 2px rgba(0,0,0,.3)
}

    .navbar.navbar-default {
        background: #1E2327;
    }
    /* Fundo Topo */
    .navbar.navbar-inverse {
        background: #2d353c
    }

        .navbar.navbar-inverse .navbar-brand, .navbar.navbar-inverse .navbar-nav > li > a {
            color: #a8acb1
        }

        .navbar.navbar-inverse .navbar-form .form-control {
            background: url(../img/transparent/black-0.2.png);
            background: rgba(0,0,0,.2);
            border-color: #1F2429;
            border-color: rgba(0,0,0,.2);
            color: #a8acb1
        }

.navbar-brand {
    margin-right: 10px;
    padding: 12px 20px;
    height: 54px;
    width: 220px;
    font-weight: 100;
    font-size: 18px;
    line-height: 30px
}

.navbar-default .navbar-brand {
    color: #333
}

.navbar-logo {
    float: left;
    margin-right: 10px;
    margin-top: 5px;
    border: 10px solid transparent;
    border-color: #4DCACA #31A3A3 #1D8888;
    opacity: .9;
    filter: alpha(opacity=90)
}

.navbar-toggle {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    border: none
}

    .navbar-toggle.pull-left {
        margin-left: 15px;
        margin-right: 0
    }

.navbar-default .navbar-toggle .icon-bar {
    background: #ffffff
}

.navbar-toggle:focus, .navbar-toggle:hover {
    background: none !important;
    opacity: .6;
    filter: alpha(opacity=60)
}

.navbar-nav > li > a {
    line-height: 20px;
    padding: 17px 15px
}
/*.navbar-nav>li>a{line-height:20px;padding:17px 15px}*/

.navbar-default .navbar-nav > li > a {
    color: #585663
}

.navbar-nav > li > a:focus, .navbar-nav > li > a:hover {
    opacity: .6;
    filter: alpha(opacity=60)
}

.navbar-nav > .open > a, .navbar-nav > .open > a:focus, .navbar-nav > .open > a:hover {
    background: none !important;
    color: #333;
    opacity: 1;
    filter: alpha(opacity=100)
}

.navbar-form {
    margin: 12px 0
}

.fade .navbar-form .form-control {
    -webkit-animation: none
}

.navbar-form .form-control {
    width: 200px;
    padding: 5px 15px;
    height: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px
}

    .navbar-form .form-control:focus {
        width: 300px;
        animation: expand .2s;
        -webkit-animation: expand .2s
    }

@keyframes expand {
    from {
        width: 200px
    }

    to {
        width: 300px
    }
}

@-webkit-keyframes expand {
    from {
        width: 200px
    }

    to {
        width: 300px
    }
}

.navbar-form .btn-search {
    position: absolute;
    right: 15px;
    top: 12px;
    height: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: none;
    background: 0 0;
    -webkit-border-radius: 0 30px 30px 0;
    -moz-border-radius: 0 30px 30px 0;
    border-radius: 0 30px 30px 0
}

.navbar-nav > li > .dropdown-menu {
    border-top: 1px solid #eee
}

    .navbar-nav > li > .dropdown-menu.media-list .media-heading {
        font-weight: 600
    }

.navbar-nav > li > a .label {
    position: absolute;
    top: 7px;
    right: 3px;
    display: block;
    background: #ff5b57;
    line-height: 12px;
    font-weight: 300;
    padding: .3em .6em;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px
}
/* .navbar-user img{float:left;width:25px;height:25px;margin: 0px 0px 0px 0px;-webkit-border-radius:30px;-moz-border-radius:30px;border-radius:30px} */
.navbar-user img {
    float: left;
    width: 25px;
    height: 25px;
    margin-top: 0px
}

.navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: #333
}

.navbar .navbar-nav > li.divider {
    height: 34px;
    margin-top: 10px;
    background: #e2e7eb;
    width: 1px
}

.navbar.navbar-inverse .navbar-nav > li.divider {
    background: #3F4B55
}

.sidebar {
    width: 220px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    padding-top: 54px;
    background: #2d353c;
    z-index: 10
}

.sidebar-bg {
    background: #2d353c;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 220px
}

/*.sidebar-bg{background:#2d353c;position:fixed;top:0;left:0;bottom:0;width:220px}*/

.sidebar .nav > li:after, .sidebar .nav > li:before, .sidebar .nav > li > a:after, .sidebar .nav > li > a:before {
    content: '';
    clear: both;
    display: table
}

.sidebar .nav > li > a {
    padding: 8px 20px;
    line-height: 20px;
    color: #a8acb1
}

.sidebar.sidebar-grid .nav > li > a {
    border-bottom: 1px solid #383f46;
    border-top: 1px solid #383f46
}

.sidebar.sidebar-grid .nav > li.active > a {
    border-color: #2d353c;
    z-index: 10
}

.sidebar.sidebar-grid .nav > li + li {
    margin-top: -1px
}

.sidebar .nav > li.active > a .badge, .sidebar .nav > li.active > a .label.label-theme {
    background: url('../img/transparent/black-0.4.png');
    background: rgba(0,0,0,.4)
}

.sidebar .nav > li > a:focus, .sidebar .nav > li > a:hover {
    background: #232a2f;
    color: #a8acb1
}



.sidebar .nav > li.active > a, .sidebar .nav > li.active > a:focus, .sidebar .nav > li.active > a:hover {
    color: #fff;
    background: #00acac
}

    .sidebar .nav > li.active > a .badge.badge-success, .sidebar .nav > li.active > a .label.label-success {
        background: #008a8a
    }

.sidebar .nav > li > a i {
    float: left;
    margin-right: 15px;
    width: 14px;
    text-align: center;
    line-height: 20px;
    font-size: 14px
}

.sidebar .nav > li > a .badge {
    margin-top: 2px;
    padding: 4px 6px;
    background: #1b1f24;
    font-weight: 300;
    font-size: 10px
}

.sidebar .nav > li > a .caret {
    float: right;
    margin-top: 9px
}

.sidebar .has-sub.active > .sub-menu {
    display: block;
}

.sidebar .sub-menu {
    list-style-type: none;
    padding: 10px 0 10px 10px;
    margin: 0;
    background: #1a2229;
    position: relative;
    display: none
}
    /*.sidebar .sub-menu{list-style-type:none;padding:10px 0 10px 30px;margin:0;background:#1a2229;position:relative;display:none}*/

    /*.sidebar .sub-menu:before{content:'';position:absolute;left:26px;top:0;bottom:0;width:2px;background:#10181F}*/
    .sidebar .sub-menu:before {
        position: absolute;
        left: 10px;
        top: 0;
        bottom: 0;
        width: 2px;
        background: #10181F
    }

    /*.sidebar .sub-menu .sub-menu{padding:0 0 0 30px;background:0 0}
.sidebar .sub-menu>li>a{padding:5px 20px;display:block;font-weight:300;color:#889097;text-decoration:none;position:relative}*/

    .sidebar .sub-menu .sub-menu {
        padding: 0 0 0 10px;
        background: 0 0;
    }

    .sidebar .sub-menu > li > a {
        padding: 5px 10px;
        display: block;
        font-weight: 300;
        color: #889097;
        text-decoration: none;
        position: relative;
    }
        /* link menu fechado */

        /*.sidebar .sub-menu>li>a:before{content:'\f10c';font-family:FontAwesome;position:absolute;left:0;font-size:7px;color:#889097;top:50%;margin-top:-4px;margin-left:-6px}*/
        .sidebar .sub-menu > li > a:before {
            font-family: FontAwesome;
            position: absolute;
            left: 0;
            font-size: 7px;
            color: #889097;
            top: 50%;
            margin-top: -4px;
            margin-left: -6px
        }

        .sidebar .sub-menu > li.active > a, .sidebar .sub-menu > li.active > a:focus, .sidebar .sub-menu > li.active > a:hover, .sidebar .sub-menu > li > a:focus, .sidebar .sub-menu > li > a:hover {
            color: #fff
        }

            .sidebar .sub-menu > li.active > a:before {
                color: #00acac
            }

.sidebar .nav .sub-menu > li > a .caret {
    float: right;
    margin-top: 7px;
}

.sidebar .nav > li.nav-header {
    margin: 0;
    padding: 10px 20px;
    line-height: 20px;
    font-size: 11px;
    color: #6d7983
}

    .sidebar .nav > li.nav-header a {
        padding: 0;
        margin: 0;
        display: inline
    }

        .sidebar .nav > li.nav-header a:focus, .sidebar .nav > li.nav-header a:hover {
            background: 0 0;
            color: #fff
        }

        .sidebar .nav > li.nav-header a i {
            float: none;
            margin: 0
        }

.sidebar .nav > li.nav-profile {
    padding: 20px;
    color: #fff;
    background: #1a2229
}

    .sidebar .nav > li.nav-profile a {
        padding: 0
    }

    .sidebar .nav > li.nav-profile .image {
        float: left;
        width: 34px;
        height: 34px;
        margin-top: 2px;
        margin-right: 15px;
        overflow: hidden;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%
    }

        .sidebar .nav > li.nav-profile .image img {
            max-width: 100%;
            max-height: 100%
        }

    .sidebar .nav > li.nav-profile .info {
        font-size: 14px
    }

        .sidebar .nav > li.nav-profile .info small {
            display: block;
            color: #889097
        }

.sidebar .nav > li.nav-widget {
    padding: 10px 20px
}

    .sidebar .nav > li.nav-widget i {
        margin-right: auto
    }

.sidebar .nav > li.active > a, .sidebar .nav > li.active > a:focus, .sidebar .nav > li.active > a:hover {
    color: #fff;
    background: #00acac
}
/* Menu dashboard */
.sidebar .nav > li.active1 > a, .sidebar .nav > li.active1 > a:focus {
    color: #fff;
    background: #3576A2
}
    /* Menu Favoritos */
    .sidebar .nav > li.active1 > a:hover {
        color: #FFFF80;
        background: #3576A2
    }
/* Menu Favoritos */

.page-sidebar-minified .sidebar {
    width: 60px;
    position: fixed;
}
/* Menu fechado */
/*.page-sidebar-minified .sidebar{width:60px;position:absolute}*/


.page-sidebar-minified .sidebar-bg {
    width: 60px
}

.page-sidebar-minified .content {
    margin-left: 60px
}

.page-sidebar-minified .footer {
    margin-left: 85px
}

.page-sidebar-minified .sidebar .slimScrollDiv, .page-sidebar-minified .sidebar .slimScrollDiv > div {
    overflow: visible !important
}

.page-sidebar-minified .sidebar .nav > li > a {
    padding: 12px 20px
}

    .page-sidebar-minified .sidebar .nav-header, .page-sidebar-minified .sidebar .nav-profile, .page-sidebar-minified .sidebar .nav > li > a > span {
        display: none
    }

.page-sidebar-minified .sidebar .caret {
    position: fixed;
    top: 9px;
    right: 9px;
    border: 4px solid transparent;
    border-left: 4px solid
}


/*.page-sidebar-minified .sidebar .caret{position:absolute;top:9px;right:9px;border:4px solid transparent;border-left:4px solid}*/

.page-sidebar-minified .sidebar .sub-menu .caret {
    top: 10px
}

.page-sidebar-minified .sidebar .nav > li > a > i {
    margin: 0
}

.page-sidebar-minified .sidebar .nav li.has-sub > .sub-menu {
    position: absolute;
    left: 100%;
    top: 0;
    width: 220px;
    display: none;
    padding: 10px 0 10px 30px;
    margin: 0;
    background: #1a2229
}

.page-sidebar-minified .sidebar .nav > li.has-sub:focus > a, .page-sidebar-minified .sidebar .nav > li.has-sub:hover > a {
    background: #232a2f
}

.page-sidebar-minified .sidebar li.has-sub > .sub-menu {
    display: none !important
}

.page-sidebar-minified .sidebar li.has-sub:focus > .sub-menu, .page-sidebar-minified .sidebar li.has-sub:hover > .sub-menu {
    display: block !important;
    overflow: visible !important
}


.sidebar-minify-btn {
    margin: 10px 0;
    float: right;
    padding: 5px 20px 5px 10px !important;
    background: #1b1f24;
    color: #fff;
    -webkit-border-radius: 20px 0 0 20px;
    -moz-border-radius: 20px 0 0 20px;
    border-radius: 20px 0 0 20px
}

    .sidebar-minify-btn i {
        margin: 0 !important;
        color: #fff
    }

.page-sidebar-minified .sidebar-minify-btn i:before {
    content: '\f101'
}

.page-sidebar-minified.page-with-right-sidebar .content {
    margin-right: 60px;
    margin-left: 0
}

.page-sidebar-minified.page-with-right-sidebar .footer {
    margin-right: 85px;
    margin-left: 25px
}

.page-sidebar-minified.page-with-right-sidebar .sub-menu {
    left: auto !important;
    right: 100%
}

.page-sidebar-minified.page-with-right-sidebar .nav > li.has-sub > a .caret {
    position: absolute;
    left: 5px;
    border: 4px solid transparent;
    border-right: 4px solid
}

.page-sidebar-minified.page-with-right-sidebar .sidebar .sub-menu .caret {
    left: 0;
    top: 2px;
    border-left: 4px solid transparent !important;
    border-right: 4px solid !important
}

.page-sidebar-minified.page-with-right-sidebar .sidebar .nav li.has-sub > .sub-menu {
    padding: 10px 30px 10px 0
}

.page-sidebar-minified.page-with-right-sidebar .sidebar .sub-menu:before {
    right: 26px;
    left: auto
}

.page-sidebar-minified.page-with-right-sidebar .sidebar .sub-menu > li > a:before {
    right: 0;
    left: auto;
    margin-right: -6px;
    margin-left: 0
}

.page-sidebar-minified.page-with-right-sidebar .sidebar-minify-btn i:before {
    content: '\f100'
}

.page-sidebar-minified .sidebar .slimScrollBar, .page-sidebar-minified .sidebar .slimScrollRail {
    display: none !important
}



.sidebar-minify-btn1 {
    margin: 0px 0;
    float: right;
    padding: 0px 20px 0px 10px !important;
    background: #1b1f24;
    color: #fff;
    -webkit-border-radius: 20px 0 0 20px;
    -moz-border-radius: 20px 0 0 20px;
    border-radius: 20px 0 0 20px
}

    .sidebar-minify-btn1 i {
        margin: 0 !important;
        color: #fff
    }

.page-sidebar-minified .sidebar-minify-btn1 i:before {
    content: '\f101'
}

.page-sidebar-minified.page-with-right-sidebar .sidebar-minify-btn1 i:before {
    content: '\f100'
}



.content {
    margin-left: 220px;
    padding: 0px 0px
}
    /* Margem Body */
    /*.content{margin-left:220px;padding:20px 25px}*/

    .content.content-full-width {
        padding: 0
    }

        .content.content-full-width .page-header {
            margin: 20px
        }

        .content.content-full-width .breadcrumb {
            margin: 0 20px 0 0
        }



    .content.content-inverse-mode .breadcrumb a, .content.content-inverse-mode .page-header, .content.content-inverse-mode h1, .content.content-inverse-mode h2, .content.content-inverse-mode h3, .content.content-inverse-mode h4, .content.content-inverse-mode h5, .content.content-inverse-mode h6 {
        color: #fff
    }

    .content.content-full-width .breadcrumb, .content.content-full-width .page-header {
        position: relative;
        z-index: 10
    }

    .content.content-full-width .breadcrumb {
        z-index: 20
    }


.page-header {
    font-size: 24px;
    margin: 0 0 20px;
    padding: 0;
    border: none;
    line-height: 28px
}

.breadcrumb {
    padding: 0;
    margin: 0;
    background: 0 0
}

    .breadcrumb > li {
        line-height: 28px
    }

        .breadcrumb > li a {
            color: #333
        }

.footer {
    z-index: 1020;
    padding: 10px 0;
    margin: 0 25px 0 245px;
    border-top: 1px solid #CAD0D6;
    line-height: 20px
}

.form-control {
    border: 0px;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: 12px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px
}

    .form-control.input-white {
        background: #fff;
        border-color: #fff
    }

        .form-control.input-white:focus {
            box-shadow: none;
            -webkit-box-shadow: none
        }

    .form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
        background: #edf0f5;
        opacity: .6;
        filter: alpha(opacity=60)
    }

        .form-control[disabled]:focus, .form-control[readonly]:focus, fieldset[disabled] .form-control:focus {
            box-shadow: none;
            -webkit-box-shadow: none;
            border: 1px solid #edf0f5
        }

    .form-control:focus {
        border-color: #b6c2c9;
        -webkit-box-shadow: none;
        box-shadow: none
    }

.form-horizontal.form-bordered .form-group {
    border-bottom: 1px solid #eee;
    margin: 0
}

    .form-horizontal.form-bordered .form-group:last-child {
        border-bottom: 0
    }

    .form-horizontal.form-bordered .form-group > .control-label {
        padding: 22px 15px 15px
    }

    .form-horizontal.form-bordered .form-group > div {
        padding: 15px;
        border-left: 1px solid #eee
    }

    .form-horizontal.form-bordered .form-group > .control-label {
        border-right: 1px solid #eee;
        margin-right: -1px
    }

.form-horizontal.form-bordered .has-feedback .form-control-feedback {
    top: 15px
}

label {
    font-family: Segoe UI;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    color: #666666;
}

.has-error .form-control, .has-error .form-control:focus, .has-success .form-control, .has-success .form-control:focus, .has-warning .form-control, .has-warning .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none
}

.has-success .checkbox, .has-success .checkbox-inline, .has-success .control-label, .has-success .form-control-feedback, .has-success .help-block, .has-success .radio, .has-success .radio-inline {
    color: #00acac
}

.has-success .form-control {
    border-color: #00acac
}

    .has-success .form-control:focus {
        border-color: #008a8a
    }

.has-warning .checkbox, .has-warning .checkbox-inline, .has-warning .control-label, .has-warning .form-control-feedback, .has-warning .help-block, .has-warning .radio, .has-warning .radio-inline {
    color: #f59c1a
}

.has-warning .form-control {
    border-color: #f59c1a
}

    .has-warning .form-control:focus {
        border-color: #c47d15
    }

.has-error .checkbox, .has-error .checkbox-inline, .has-error .control-label, .has-error .form-control-feedback, .has-error .help-block, .has-error .radio, .has-error .radio-inline {
    color: #ff5b57
}

.has-error .form-control {
    border-color: #ff5b57
}

    .has-error .form-control:focus {
        border-color: #cc4946
    }

select.form-control {
    border-color: #999999
}

select[multiple].form-control {
    border-color: #999999
}

.input-group-addon {
    background: #e2e7eb;
    border: none
}



.dropdown-menu {
    border: none;
    -webkit-box-shadow: 0 2px 5px -1px rgba(0,0,0,.2);
    box-shadow: 0 2px 5px -1px rgba(0,0,0,.2);
    font-size: 12px
}

    .dropdown-menu > li > a {
        padding: 5px 15px
    }

        .dropdown-menu > li > a:focus, .dropdown-menu > li > a:hover {
            background: #edf0f5
        }

    .dropdown-menu > .active > a, .dropdown-menu > .active > a:focus, .dropdown-menu > .active > a:hover {
        background: #348fe2
    }

    .dropdown-menu .divider {
        border-color: #eee
    }

    .dropdown-menu.media-list {
        max-width: 280px;
        padding: 0
    }

        .dropdown-menu.media-list p {
            text-overflow: ellipsis;
            overflow: hidden
        }

        .dropdown-menu.media-list .dropdown-header {
            padding: 10px 20px !important;
            background: #fafafa
        }

        .dropdown-menu.media-list > .media {
            margin-top: 0;
            border-top: 1px solid #eee;
            border-bottom: 1px solid #eee;
            margin-bottom: -1px
        }

            .dropdown-menu.media-list > .media > a {
                display: block;
                padding: 10px 20px !important
            }

            .dropdown-menu.media-list > .media .media-object {
                height: 36px;
                width: 36px;
                line-height: 36px;
                font-size: 14px;
                color: #fff;
                text-align: center;
                margin-right: 10px;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                border-radius: 50%
            }

.dropdown-footer {
    padding: 10px 20px
}

.dropdown-menu > li.dropdown-footer > a {
    padding: 0 !important;
    display: inline !important
}

    .dropdown-menu > li.dropdown-footer > a:focus, .dropdown-menu > li.dropdown-footer > a:hover {
        background: none !important;
        text-decoration: underline !important
    }


.tooltip-inner {
    padding: 4px 10px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px
}

.alert {
    border: none
}

    .alert.alert-success {
        background: #7cdda7
    }

    .alert.alert-info {
        background: #93cfe5
    }

    .alert.alert-danger {
        background: #f8b2b2
    }

    .alert.alert-warning {
        background: #ffead0
    }

.note {
    margin-bottom: 20px;
    padding: 15px;
    border-left: 3px solid
}

    .note.note-success {
        border-color: #4a8564;
        background: #b0ebca;
        color: #3c763d
    }

        .note.note-success h1, .note.note-success h2, .note.note-success h3, .note.note-success h4, .note.note-success h5, .note.note-success h6 {
            color: #3c763d
        }

    .note.note-danger {
        border-color: #986e6e;
        background: #fbd1d1;
        color: #a94442
    }

        .note.note-danger h1, .note.note-danger h2, .note.note-danger h3, .note.note-danger h4, .note.note-danger h5, .note.note-danger h6 {
            color: #a94442
        }

    .note.note-info {
        border-color: #587c89;
        background: #bee2ef;
        color: #31708f
    }

        .note.note-info h1, .note.note-info h2, .note.note-info h3, .note.note-info h4, .note.note-info h5, .note.note-info h6 {
            color: #31708f
        }

    .note.note-warning {
        border-color: #9d9080;
        background: #fff2e3;
        color: #8a6d3b
    }

        .note.note-warning h1, .note.note-warning h2, .note.note-warning h3, .note.note-warning h4, .note.note-warning h5, .note.note-warning h6 {
            color: #8a6d3b
        }

.badge {
    font-size: 75%;
    line-height: 1.25;
    font-weight: 600
}

.label {
    font-size: 75%;
    font-weight: 600
}

.badge.badge-square {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0
}

.badge.badge-default, .label.label-default {
    background: #b6c2c9
}

.badge.badge-danger, .label.label-danger {
    background: #ff5b57
}

.badge.badge-warning, .label.label-warning {
    background: #f59c1a
}

.badge.badge-success, .label.label-success {
    background: #00acac
}

.badge.badge-info, .label.label-info {
    background: #49b6d6
}

.badge.badge-primary, .label.label-primary {
    background: #348fe2
}

.badge.badge-inverse, .label.label-inverse {
    background: #2d353c
}

.pager li > a, .pager li > span, .pagination > li > a {
    border-color: #e2e7eb;
    color: #242a30
}

.pager.pager-without-border li > a, .pager.pager-without-border li > span, .pagination.pagination-without-border > li > a {
    border-color: #fff
}

.pager > .disabled > a, .pager > .disabled > span, .pagination > .disabled > a, .pagination > .disabled > a:focus, .pagination > .disabled > a:hover, .pagination > .disabled > span, .pagination > .disabled > span:focus, .pagination > .disabled > span:hover {
    opacity: .6;
    filter: alpha(opacity=60);
    border-color: #ddd
}

.pagination > li > a {
    color: #242a30;
    margin-left: 5px;
    -webkit-border-radius: 3px !important;
    -moz-border-radius: 3px !important;
    border-radius: 3px !important
}

.pagination > li:first-child > a {
    margin-left: 0
}

.pagination-sm > li > a, .pagination-sm > li > span {
    font-size: 10px;
    margin-left: 4px
}

.pagination-lg > li > a, .pagination-lg > li > span {
    font-size: 14px;
    margin-left: 6px
}

.pager li > a:focus, .pager li > a:hover, .pager li > span:focus, .pager li > span:hover, .pagination > li > a:focus, .pagination > li > a:hover {
    color: #242a30;
    background: #e2e7eb;
    border-color: #d8dde1
}

.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
    background: #242a30 !important;
    border-color: #242a30 !important
}

.progress {
    -webkit-box-shadow: none;
    box-shadow: none;
    background: #e2e7eb
}

.progress-xs {
    height: 5px
}

    .progress-xs .progress-bar {
        line-height: 5px
    }

.progress-sm {
    height: 10px
}

    .progress-sm .progress-bar {
        line-height: 10px
    }

.progress-lg {
    height: 30px
}

    .progress-lg .progress-bar {
        line-height: 30px
    }

.progress-bar {
    background: #348fe2;
    -webkit-box-shadow: none;
    box-shadow: none
}

    .progress-bar.progress-bar-success {
        background-color: #00acac
    }

    .progress-bar.progress-bar-info {
        background-color: #49b6d6
    }

    .progress-bar.progress-bar-warning {
        background-color: #f59c1a
    }

    .progress-bar.progress-bar-danger {
        background-color: #ff5b57
    }

    .progress-bar.progress-bar-inverse {
        background-color: #2d353c
    }

.nav > li > a {
    color: #6e7179
}

    .nav > li > a:focus, .nav > li > a:hover {
        color: #333;
        background: #fafafa
    }

.nav-tabs, .nav-tabs.nav-justified > .active > a, .nav-tabs.nav-justified > .active > a:focus, .nav-tabs.nav-justified > .active > a:hover, .nav-tabs.nav-justified > li > a, .nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover, .nav-tabs > li > a {
    border: none !important
}

    .nav-tabs.nav-justified > .active > a, .nav-tabs.nav-justified > .active > a:focus, .nav-tabs.nav-justified > .active > a:hover, .nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
        color: #242a30
    }

.nav-tabs {
    background: #c1ccd1;
    -webkit-border-radius: 5px 5px 0 0;
    -moz-border-radius: 5px 5px 0 0;
    border-radius: 5px 5px 0 0
}

    .nav-tabs.nav-tabs-inverse {
        background: #242a30
    }

    .nav-tabs.nav-justified > li > a {
        -webkit-border-radius: 3px 3px 0 0;
        -moz-border-radius: 3px 3px 0 0;
        border-radius: 3px 3px 0 0
    }

    .nav-tabs.nav-tabs-inverse > li.active > a, .nav-tabs.nav-tabs-inverse > li.active > a:focus, .nav-tabs.nav-tabs-inverse > li.active > a:hover {
        background: #fff
    }

    .nav-tabs.nav-tabs-inverse > li > a:focus, .nav-tabs.nav-tabs-inverse > li > a:hover {
        color: #fff;
        background: 0 0
    }

    .nav-tabs.nav-justified > li, .nav-tabs > li {
        margin-bottom: 0
    }

        .nav-tabs > li > a {
            margin-right: 5px;
            line-height: 20px
        }

.nav-pills {
    margin-bottom: 10px
}

    .nav-pills > li + li {
        margin-left: 5px
    }

    .nav-pills > li > a {
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px
    }

    .nav-pills > li.active > a, .nav-pills > li.active > a:focus, .nav-pills > li.active > a:hover {
        background: #242a30
    }

.nav-stacked > li + li {
    margin-left: 0;
    margin-top: 5px
}

.tab-content {
    padding: 15px;
    margin-bottom: 20px;
    background: #fff;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px
}

.nav-tabs + .tab-content {
    -webkit-border-radius: 0 0 3px 3px;
    -moz-border-radius: 0 0 3px 3px;
    border-radius: 0 0 3px 3px
}

.panel-title a {
    display: block
}

.panel-title > a:focus, .panel-title > a:hover {
    text-decoration: none
}

.btn {
    font-weight: 300;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    font-size: 13px !important;
}

    .btn.active:focus, .btn:active:focus, .btn:focus {
        outline: 0
    }

    .btn-icon, .btn.btn-icon {
        display: inline-block;
        width: 28px;
        height: 28px;
        padding: 0;
        border: none;
        line-height: 28px;
        text-align: center;
        font-size: 14px
    }

    .btn-circle, .btn.btn-circle {
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%
    }

.btn-icon.btn-xs {
    width: 16px;
    height: 16px;
    font-size: 8px;
    line-height: 16px
}

.btn-icon.btn-sm {
    width: 22px;
    height: 22px;
    font-size: 11px;
    line-height: 22px
}

.btn-icon.btn-lg {
    width: 34px;
    height: 34px;
    font-size: 17px;
    line-height: 34px
}

.btn-scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 25px;
    z-index: 1020
}

.btn > .pull-left, .btn > .pull-right {
    line-height: 1.428571429
}

.btn-block {
    padding-left: 12px;
    padding-right: 12px
}

.btn.active, .btn:active {
    /*
        -webkit-box-shadow: inset 0 3px 5px rgba(0,0,0,.1);
        box-shadow: inset 0 3px 5px rgba(0,0,0,.1)

    */
}

.btn.btn-default {
    color: #fff;
    background: #b6c2c9;
    border-color: #b6c2c9
}

.btn-default.active, .btn-default:active, .btn-default:focus, .btn-default:hover, .open .dropdown-toggle.btn-default {
    background: #929ba1;
    border-color: #929ba1
}

.btn-group .btn.btn-default:not(.active) + .btn.btn-default, .input-group-btn .btn.btn-default:not(.active) + .btn.btn-default {
    border-left-color: #929ba1
}

.btn.btn-white {
    font-weight: 400;
    color: #333;
    background: #fff;
    border-color: #e2e7eb
}

    .btn.btn-white.btn-white-without-border {
        border-color: #fff
    }

        .btn.btn-white.btn-white-without-border.active, .btn.btn-white.btn-white-without-border.active:focus, .btn.btn-white.btn-white-without-border.active:hover {
            border-color: #ddd
        }

        .btn.btn-white.btn-white-without-border:focus, .btn.btn-white.btn-white-without-border:hover {
            border-color: #eee
        }

.btn-white.active, .btn-white:active, .btn-white:focus, .btn-white:hover, .open .dropdown-toggle.btn-white {
    background: #e2e7eb;
    border-color: #d8dde1
}

.btn-group .btn.btn-white:not(.active) + .btn.btn-white, .input-group-btn .btn.btn-white:not(.active) + .btn.btn-white {
    border-left-color: #eee
}

.btn.btn-inverse {
    color: #fff;
    background: #2d353c;
    border-color: #2d353c
}

.btn-inverse.active, .btn-inverse:active, .btn-inverse:focus, .btn-inverse:hover, .open .dropdown-toggle.btn-inverse {
    background: #242a30;
    border-color: #242a30
}

.btn-group .btn.btn-inverse:not(.active) + .btn.btn-inverse, .input-group-btn .btn.btn-inverse:not(.active) + .btn.btn-inverse {
    border-left-color: #242a30
}



.btn.btn-light {
    color: var(--primary-color);
    background: white;
    border-color: var(--primary-color);
}

.btn.btn-primary:hover {
    color: #aaa;
    border-color: var(--primary-color );
    transition: font-weight .3s ease;
    font-weight: 600;
}


.btn-primary.active, .btn-primary:active, .btn-primary:focus, .btn-primary:hover, .open .dropdown-toggle.btn-primary {

    border-color: #2a72b5
}

.btn-group .btn.btn-primary:not(.active) + .btn.btn-primary, .input-group-btn .btn.btn-primary:not(.active) + .btn.btn-primary {
    border-left-color: #2a72b5
}

.btn.btn-success {
    color: #fff;
    background: #00acac;
    border-color: #00acac
}

    .btn.btn-success.active, .btn.btn-success:active, .btn.btn-success:focus, .btn.btn-success:hover, .open .dropdown-toggle.btn-success {
        background: #008a8a;
        border-color: #008a8a
    }

.btn-group .btn.btn-success:not(.active) + .btn.btn-success, .input-group-btn .btn.btn-success:not(.active) + .btn.btn-success {
    border-left-color: #008a8a
}

.btn.btn-warning {
    color: #fff;
    background: #f59c1a;
    border-color: #f59c1a
}

.btn-warning.active, .btn-warning:active, .btn-warning:focus, .btn-warning:hover, .open .dropdown-toggle.btn-warning {
    background: #c47d15;
    border-color: #c47d15
}

.btn-group .btn.btn-warning:not(.active) + .btn.btn-warning, .input-group-btn .btn.btn-warning:not(.active) + .btn.btn-warning {
    border-left-color: #c47d15
}

.btn.btn-danger {
    color: #fff;
    background: #ff5b57;
    border-color: #ff5b57
}

.btn-danger.active, .btn-danger:active, .btn-danger:focus, .btn-danger:hover, .open .dropdown-toggle.btn-danger {
    background: #cc4946;
    border-color: #cc4946
}

.btn-group .btn.btn-danger:not(.active) + .btn.btn-danger, .input-group-btn .btn.btn-danger:not(.active) + .btn.btn-danger {
    border-left-color: #cc4946
}

.btn.btn-info {
    color: #fff;
    background: #49b6d6;
    border-color: #49b6d6
}

.btn-info.active, .btn-info:active, .btn-info:focus, .btn-info:hover, .open .dropdown-toggle.btn-info {
    background: #3a92ab;
    border-color: #3a92ab
}

.btn-group .btn.btn-info:not(.active) + .btn.btn-info, .input-group-btn .btn.btn-info:not(.active) + .btn.btn-info {
    border-left-color: #3a92ab
}

.panel {
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;

    background-color: #ffffff;
    /*margin-top: 20px;*/
    min-height: 400px;
    padding: 10px;
    padding-left:30px;
}

    .panel.panel-no-rounded-corner .panel-body, .panel.panel-no-rounded-corner .panel-footer, .panel.panel-no-rounded-corner .panel-heading {
        -webkit-border-radius: 0 !important;
        -moz-border-radius: 0 !important;
        border-radius: 0 !important
    }

.panel-heading {
    padding: 10px 15px;
    border: none
}

    .panel-heading + .slimScrollDiv, .panel-heading + .table {
        border-top: 1px solid #eee
    }

.panel-heading-btn {
    float: right
}

    .panel-heading-btn > a {
        margin-left: 8px
    }

.panel-heading .btn-group .btn {
    margin-top: -7px
}

    .panel-heading .btn-group .btn.btn-sm {
        margin-top: -5px
    }

    .panel-heading .btn-group .btn.btn-xs {
        margin-top: -1px
    }

.panel-heading .label.pull-left, .panel-heading .label.pull-right {
    line-height: 15px
}

.panel-heading .progress.pull-left, .panel-heading .progress.pull-right {
    width: 40%;
    min-width: 120px
}

.panel-heading + .alert {
    margin-bottom: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0
}

.panel-with-tabs .panel-heading {
    background: #c1ccd1 !important;
    color: #333 !important
}

.panel-heading .nav-tabs {
    margin-top: -10px;
    margin-right: -15px
}

    .panel-heading .nav-tabs > li > a {
        padding: 10px 15px;
        line-height: 20px
    }

.panel-title {
    line-height: 20px;
    font-size: 12px
}

    .panel-title .accordion-toggle {
        margin: -10px -15px;
        padding: 10px 15px
    }

        .panel-title .accordion-toggle.accordion-toggle-styled .fa:before {
            content: '\f056'
        }

        .panel-title .accordion-toggle.accordion-toggle-styled.collapsed .fa:before {
            content: '\f055'
        }

    .panel-title .pull-right {
        line-height: 20px
    }

.panel-toolbar {
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    padding: 10px 15px;
    background: #fff
}

    .panel-toolbar + .form-control {
        margin: -1px 0 0;
        border-right: none;
        border-left: none
    }

.panel-group .panel .panel-heading {
    -webkit-border-top-right-radius: 0;
    -webkit-border-top-left-radius: 0;
    -moz-border-top-right-radius: 0;
    -moz-border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0
}

.form-control + .panel-footer {
    border-top: none
}

.panel-body {
    padding: 15px;
    border-radius: 18px;
    background-color: #F0F0F0;
    margin-top:30px;
    /*background-color: #F8F8F8;*/
}

.panel-body.fundoBranco {
    padding: 15px;
    border-radius: 18px;
    background-color: #FFFFFF;
    margin-top: 30px;
    /*background-color: #F8F8F8;*/
}

    .panel-body.no-border {
        border: none !important
    }

    .panel-body.no-padding, .panel-body.panel-form, .panel-body.panel-full-width, .panel-body.panel-table {
        padding: 0 !important
    }

    .panel-body.with-table > .table {
        border: 0;
        margin: 0
    }

        .panel-body.with-table > .table tr:last-child td, .panel-body.with-table > .table tr:last-child th {
            border-bottom: 0
        }

.panel-default > .panel-heading + .panel-collapse .panel-body {
    border-top: 1px solid #eee
}

.panel-footer {
    background: #fff;
    border-top: 1px solid #eee
}

.panel .tab-content {
    -webkit-border-radius: 0 0 3px 3px;
    -moz-border-radius: 0 0 3px 3px;
    border-radius: 0 0 3px 3px
}

.panel-default > .panel-heading {
    background: #fafafa
}

.panel-danger > .panel-heading, .panel-info > .panel-heading, .panel-inverse > .panel-heading, .panel-primary > .panel-heading, .panel-success > .panel-heading, .panel-warning > .panel-heading {
    color: #fff
}

.panel-inverse > .panel-heading {
    background: #242a30
}

.panel-success > .panel-heading {
    background: #008a8a
}

.panel-warning > .panel-heading {
    background: #c47d15
}

.panel-danger > .panel-heading {
    background: #cc4946
}

.panel-primary > .panel-heading {
    background: #2a72b5
}

.panel-info > .panel-heading {
    background: #3a92ab
}

.panel.panel-expand {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    overflow: hidden;
    z-index: 1080
}

.panel-expand .height-full, .panel-expand .height-lg, .panel-expand .height-md, .panel-expand .height-sm, .panel-expand .height-xs {
    height: 100% !important
}

@keyframes panelExpand {
    from {
        top: 50%;
        left: 50%;
        right: 50%;
        bottom: 50%
    }

    to {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0
    }
}

@-webkit-keyframes panelExpand {
    from {
        top: 50%;
        left: 50%;
        right: 50%;
        bottom: 50%
    }

    to {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0
    }
}

.panel.panel-expand > .panel-heading .fa.fa-expand:before {
    content: '\f066'
}

.panel.panel-expand, .panel.panel-expand > .panel-body, .panel.panel-expand > .panel-heading {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0
}

    .panel.panel-expand > .panel-body {
        position: absolute;
        right: 0;
        left: 0;
        bottom: 0;
        top: 40px;
        overflow-y: scroll;
        z-index: 1020
    }

    .panel.panel-expand > .panel-footer {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0
    }

.panel.panel-loading .panel-body {
    position: relative
}

.panel.panel-loading.panel-expand > .panel-body {
    position: absolute
}

.panel.panel-loading .panel-body .panel-loader {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #fff;
    opacity: .9;
    filter: alpha(opacity=90);
    animation: fadeIn .2s;
    -webkit-animation: fadeIn .2s;
    z-index: 1020
}

@keyframes fadeIn {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

.modal-content {
    border: none;
    -webkit-box-shadow: 0 5px 15px rgba(0,0,0,.3);
    box-shadow: 0 5px 15px rgba(0,0,0,.3);
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px
}

.modal-header {
    padding: 12px 15px;
    border-bottom-color: #e2e7eb
}

    .modal-header .close {
        margin-top: 2px
    }

.modal-body {
    padding: 15px
}

.modal-footer {
    border-top-color: #e2e7eb;
    padding: 14px 15px 15px
}

.modal-message .modal-dialog {
    width: 100%
}

.modal-message .modal-content {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0
}

.modal-message .modal-body, .modal-message .modal-footer, .modal-message .modal-header {
    width: 90%;
    border: none;
    margin: 0 auto
}

.media .media-object {
    width: 128px
}

.media.media-lg .media-object {
    width: 256px
}

.media.media-sm .media-object {
    width: 64px
}

.media.media-xs .media-object {
    width: 32px
}

.media > .pull-left {
    margin-right: 15px
}

.media > .pull-right {
    margin-left: 15px
}

.media a.media-heading:focus, .media a.media-heading:hover, .media a:focus, .media a:focus .media-heading, .media a:hover, .media a:hover .media-heading {
    color: #242a30;
    text-decoration: none
}

.media-list.media-list-with-divider > li + li {
    border-top: 1px solid #eee;
    padding-top: 20px
}

.table {
    border-color: #e2e7eb;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px
}

    .table > thead > tr > th {
        color: #242a30;
        font-weight: 600;
        border-bottom: 2px solid #e2e7eb !important
    }

    .table > tbody > tr > td, .table > tbody > tr > th, .table > tfoot > tr > td, .table > tfoot > tr > th, .table > thead > tr > td, .table > thead > tr > th {
        border-color: #e2e7eb;
        padding: 10px 15px
    }

.table-condensed > tbody > tr > td, .table-condensed > tbody > tr > th, .table-condensed > tfoot > tr > td, .table-condensed > tfoot > tr > th, .table-condensed > thead > tr > td, .table-condensed > thead > tr > th {
    padding: 7px 15px
}

.table-hover > tbody > tr:hover > td, .table-hover > tbody > tr:hover > th {
    background: #e8ecf1 !important
}

.table-striped > tbody > tr:nth-child(odd) > td, .table-striped > tbody > tr:nth-child(odd) > th {
    background: #f0f3f5
}

.table.table-inverse > tbody > tr > td, .table.table-inverse > tbody > tr > th, .table.table-inverse > tfoot > tr > td, .table.table-inverse > tfoot > tr > th, .table.table-inverse > thead > tr > td, .table.table-inverse > thead > tr > th {
    border-color: #999 !important;
    border-color: rgba(0,0,0,.2) !important
}

.table.table-inverse, .table.table-inverse > tbody > tr > th, .table.table-inverse > tfoot > tr > th, .table.table-inverse > thead > tr > th {
    color: #fff
}

.table > tbody > tr.info > td, .table > tbody > tr.info > th, .table > tbody > tr > td.info, .table > tbody > tr > th.info, .table > tfoot > tr.info > td, .table > tfoot > tr.info > th, .table > tfoot > tr > td.info, .table > tfoot > tr > th.info, .table > thead > tr.info > td, .table > thead > tr.info > th, .table > thead > tr > td.info, .table > thead > tr > th.info {
    background: #dbf0f7;
    border-color: #b6e2ef
}

.table > tbody > tr.success > td, .table > tbody > tr.success > th, .table > tbody > tr > td.success, .table > tbody > tr > th.success, .table > tfoot > tr.success > td, .table > tfoot > tr.success > th, .table > tfoot > tr > td.success, .table > tfoot > tr > th.success, .table > thead > tr.success > td, .table > thead > tr.success > th, .table > thead > tr > td.success, .table > thead > tr > th.success {
    background: #cee;
    border-color: #99dede
}

.table > tbody > tr.danger > td, .table > tbody > tr.danger > th, .table > tbody > tr > td.danger, .table > tbody > tr > th.danger, .table > tfoot > tr.danger > td, .table > tfoot > tr.danger > th, .table > tfoot > tr > td.danger, .table > tfoot > tr > th.danger, .table > thead > tr.danger > td, .table > thead > tr.danger > th, .table > thead > tr > td.danger, .table > thead > tr > th.danger {
    background: #ffdedd;
    border-color: #ffbdbc
}

.table > tbody > tr.warning > td, .table > tbody > tr.warning > th, .table > tbody > tr > td.warning, .table > tbody > tr > th.warning, .table > tfoot > tr.warning > td, .table > tfoot > tr.warning > th, .table > tfoot > tr > td.warning, .table > tfoot > tr > th.warning, .table > thead > tr.warning > td, .table > thead > tr.warning > th, .table > thead > tr > td.warning, .table > thead > tr > th.warning {
    background: #fdebd1;
    border-color: #fbd7a3
}

.table > tbody > tr.active > td, .table > tbody > tr.active > th, .table > tbody > tr > td.active, .table > tbody > tr > th.active, .table > tfoot > tr.active > td, .table > tfoot > tr.active > th, .table > tfoot > tr > td.active, .table > tfoot > tr > th.active, .table > thead > tr.active > td, .table > thead > tr.active > th, .table > thead > tr > td.active, .table > thead > tr > th.active {
    background: #f0f3f5;
    border-color: #e2e7e9
}

.well {
    padding: 15px;
    background: #fff;
    box-shadow: none;
    -webkit-box-shadow: none
}

.well-sm {
    padding: 10px
}

.well-lg {
    padding: 30px
}

.jumbotron {
    background: #f0f3f4
}

    .jumbotron .h1, .jumbotron h1 {
        font-size: 56px
    }

    .jumbotron p {
        font-size: 18px
    }

a.list-group-item.active, a.list-group-item.active:focus, a.list-group-item.active:hover {
    background: #348fe2
}

.carousel .carousel-control .fa {
    position: absolute;
    top: 50%;
    z-index: 5;
    display: block;
    width: 30px;
    height: 30px;
    margin-top: -15px;
    text-align: center;
    line-height: 30px;
    margin-left: -15px
}

.carousel .carousel-control.left .fa {
    margin-left: 15px
}

.row {
    margin: 0 -10px
}

    .row > [class*=col-] {
        padding: 0 10px
    }

    .row.row-space-0 {
        margin: 0
    }

    .row.row-space-2 {
        margin: 0 -1px
    }

    .row.row-space-4 {
        margin: 0 -2px
    }

    .row.row-space-6 {
        margin: 0 -3px
    }

    .row.row-space-8 {
        margin: 0 -4px
    }

    .row.row-space-10 {
        margin: 0 -5px
    }

    .row.row-space-12 {
        margin: 0 -6px
    }

    .row.row-space-14 {
        margin: 0 -7px
    }

    .row.row-space-16 {
        margin: 0 -8px
    }

    .row.row-space-18 {
        margin: 0 -10px
    }

    .row.row-space-22 {
        margin: 0 -11px
    }

    .row.row-space-24 {
        margin: 0 -12px
    }

    .row.row-space-26 {
        margin: 0 -13px
    }

    .row.row-space-28 {
        margin: 0 -14px
    }

    .row.row-space-30 {
        margin: 0 -15px
    }

    .row.row-space-0 > [class*=col-] {
        padding: 0
    }

    .row.row-space-2 > [class*=col-] {
        padding: 0 1px
    }

    .row.row-space-4 > [class*=col-] {
        padding: 0 2px
    }

    .row.row-space-6 > [class*=col-] {
        padding: 0 3px
    }

    .row.row-space-8 > [class*=col-] {
        padding: 0 4px
    }

    .row.row-space-10 > [class*=col-] {
        padding: 0 5px
    }

    .row.row-space-12 > [class*=col-] {
        padding: 0 6px
    }

    .row.row-space-14 > [class*=col-] {
        padding: 0 7px
    }

    .row.row-space-16 > [class*=col-] {
        padding: 0 8px
    }

    .row.row-space-18 > [class*=col-] {
        padding: 0 9px
    }

    .row.row-space-20 > [class*=col-] {
        padding: 0 10px
    }

    .row.row-space-22 > [class*=col-] {
        padding: 0 11px
    }

    .row.row-space-24 > [class*=col-] {
        padding: 0 12px
    }

    .row.row-space-26 > [class*=col-] {
        padding: 0 13px
    }

    .row.row-space-28 > [class*=col-] {
        padding: 0 14px
    }

    .row.row-space-30 > [class*=col-] {
        padding: 0 15px
    }

.semi-bold {
    font-weight: 600
}

.overflow-auto {
    overflow: auto !important
}

.overflow-hidden {
    overflow: hidden !important
}

.overflow-visible {
    overflow: visible !important
}

.overflow-scroll {
    overflow: scroll !important
}

.overflow-x-hidden {
    overflow-x: hidden !important
}

.overflow-x-visible {
    overflow-x: visible !important
}

.overflow-x-scroll {
    overflow-x: scroll !important
}

.overflow-y-hidden {
    overflow-y: hidden !important
}

.overflow-y-visible {
    overflow-y: visible !important
}

.overflow-y-scroll {
    overflow-y: scroll !important
}

.m-auto {
    margin: 0 auto !important
}

.m-0 {
    margin: 4px !important
}

.m-5 {
    margin: 5px !important
}

.m-10 {
    margin: 10px !important
}

.m-15 {
    margin: 15px !important
}

.m-20 {
    margin: 20px !important
}

.m-25 {
    margin: 25px !important
}

.m-30 {
    margin: 30px !important
}

.m-35 {
    margin: 35px !important
}

.m-40 {
    margin: 40px !important
}

.m-t-0 {
    margin-top: 0 !important
}

.m-t-1 {
    margin-top: 1px !important
}

.m-t-2 {
    margin-top: 2px !important
}

.m-t-3 {
    margin-top: 3px !important
}

.m-t-4 {
    margin-top: 4px !important
}

.m-t-5 {
    margin-top: 5px !important
}

.m-t-10 {
    margin-top: 10px !important
}

.m-t-15 {
    margin-top: 15px !important
}

.m-t-20 {
    margin-top: 20px !important
}

.m-t-25 {
    margin-top: 25px !important
}

.m-t-30 {
    margin-top: 30px !important
}

.m-t-35 {
    margin-top: 35px !important
}

.m-t-40 {
    margin-top: 40px !important
}

.m-r-0 {
    margin-right: 0 !important
}

.m-r-1 {
    margin-right: 1px !important
}

.m-r-2 {
    margin-right: 2px !important
}

.m-r-3 {
    margin-right: 3px !important
}

.m-r-4 {
    margin-right: 4px !important
}

.m-r-5 {
    margin-right: 5px !important
}

.m-r-10 {
    margin-right: 10px !important
}

.m-r-15 {
    margin-right: 15px !important
}

.m-r-20 {
    margin-right: 20px !important
}

.m-r-25 {
    margin-right: 25px !important
}

.m-r-30 {
    margin-right: 30px !important
}

.m-r-35 {
    margin-right: 35px !important
}

.m-r-40 {
    margin-right: 40px !important
}

.m-b-0 {
    margin-bottom: 0 !important
}

.m-b-1 {
    margin-bottom: 1px !important
}

.m-b-2 {
    margin-bottom: 2px !important
}

.m-b-3 {
    margin-bottom: 3px !important
}

.m-b-4 {
    margin-bottom: 4px !important
}

.m-b-5 {
    margin-bottom: 5px !important
}

.m-b-10 {
    margin-bottom: 10px !important
}

.m-b-15 {
    margin-bottom: 15px !important
}

.m-b-20 {
    margin-bottom: 20px !important
}

.m-b-25 {
    margin-bottom: 25px !important
}

.m-b-30 {
    margin-bottom: 30px !important
}

.m-b-35 {
    margin-bottom: 35px !important
}

.m-b-40 {
    margin-bottom: 40px !important
}

.m-l-0 {
    margin-left: 0 !important
}

.m-l-1 {
    margin-left: 1px !important
}

.m-l-2 {
    margin-left: 2px !important
}

.m-l-3 {
    margin-left: 3px !important
}

.m-l-4 {
    margin-left: 4px !important
}

.m-l-5 {
    margin-left: 5px !important
}

.m-l-10 {
    margin-left: 10px !important
}

.m-l-15 {
    margin-left: 15px !important
}

.m-l-20 {
    margin-left: 20px !important
}

.m-l-25 {
    margin-left: 25px !important
}

.m-l-30 {
    margin-left: 30px !important
}

.m-l-35 {
    margin-left: 35px !important
}

.m-l-40 {
    margin-left: 40px !important
}

.p-0 {
    padding: 0 !important
}

.p-1 {
    padding: 1px !important
}

.p-2 {
    padding: 2px !important
}

.p-3 {
    padding: 3px !important
}

.p-4 {
    padding: 4px !important
}

.p-5 {
    padding: 5px !important
}

.p-10 {
    padding: 10px !important
}

.p-15 {
    padding: 15px !important
}

.p-20 {
    padding: 20px !important
}

.p-25 {
    padding: 25px !important
}

.p-30 {
    padding: 30px !important
}

.p-35 {
    padding: 35px !important
}

.p-40 {
    padding: 40px !important
}

.p-t-0 {
    padding-top: 0 !important
}

.p-t-1 {
    padding-top: 1px !important
}

.p-t-2 {
    padding-top: 2px !important
}

.p-t-3 {
    padding-top: 3px !important
}

.p-t-4 {
    padding-top: 4px !important
}

.p-t-5 {
    padding-top: 5px !important
}

.p-t-10 {
    padding-top: 10px !important
}

.p-t-15 {
    padding-top: 15px !important
}

.p-t-20 {
    padding-top: 20px !important
}

.p-t-25 {
    padding-top: 25px !important
}

.p-t-30 {
    padding-top: 30px !important
}

.p-t-35 {
    padding-top: 35px !important
}

.p-t-40 {
    padding-top: 40px !important
}

.p-r-0 {
    padding-right: 0 !important
}

.p-r-1 {
    padding-right: 1px !important
}

.p-r-2 {
    padding-right: 2px !important
}

.p-r-3 {
    padding-right: 3px !important
}

.p-r-4 {
    padding-right: 4px !important
}

.p-r-5 {
    padding-right: 5px !important
}

.p-r-10 {
    padding-right: 10px !important
}

.p-r-15 {
    padding-right: 15px !important
}

.p-r-20 {
    padding-right: 20px !important
}

.p-r-25 {
    padding-right: 25px !important
}

.p-r-30 {
    padding-right: 30px !important
}

.p-r-35 {
    padding-right: 35px !important
}

.p-r-40 {
    padding-right: 40px !important
}

.p-b-0 {
    padding-bottom: 0 !important
}

.p-b-1 {
    padding-bottom: 1px !important
}

.p-b-2 {
    padding-bottom: 2px !important
}

.p-b-3 {
    padding-bottom: 3px !important
}

.p-b-4 {
    padding-bottom: 4px !important
}

.p-b-5 {
    padding-bottom: 5px !important
}

.p-b-10 {
    padding-bottom: 10px !important
}

.p-b-15 {
    padding-bottom: 15px !important
}

.p-b-20 {
    padding-bottom: 20px !important
}

.p-b-25 {
    padding-bottom: 25px !important
}

.p-b-30 {
    padding-bottom: 30px !important
}

.p-b-35 {
    padding-bottom: 35px !important
}

.p-b-40 {
    padding-bottom: 40px !important
}

.p-l-0 {
    padding-left: 0 !important
}

.p-l-1 {
    padding-left: 1px !important
}

.p-l-2 {
    padding-left: 2px !important
}

.p-l-3 {
    padding-left: 3px !important
}

.p-l-4 {
    padding-left: 4px !important
}

.p-l-5 {
    padding-left: 5px !important
}

.p-l-10 {
    padding-left: 10px !important
}

.p-l-15 {
    padding-left: 15px !important
}

.p-l-20 {
    padding-left: 20px !important
}

.p-l-25 {
    padding-left: 25px !important
}

.p-l-30 {
    padding-left: 30px !important
}

.p-l-35 {
    padding-left: 35px !important
}

.p-l-40 {
    padding-left: 40px !important
}

.f-s-8 {
    font-size: 8px !important
}

.f-s-9 {
    font-size: 9px !important
}

.f-s-10 {
    font-size: 10px !important
}

.f-s-11 {
    font-size: 11px !important
}

.f-s-12 {
    font-size: 12px !important
}

.f-s-13 {
    font-size: 13px !important
}

.f-s-14 {
    font-size: 14px !important
}

.f-s-15 {
    font-size: 15px !important
}

.f-s-16 {
    font-size: 16px !important
}

.f-s-17 {
    font-size: 17px !important
}

.f-s-18 {
    font-size: 18px !important
}

.f-s-19 {
    font-size: 19px !important
}

.f-s-20 {
    font-size: 20px !important
}
.p-10{
    overflow: auto;
}
@media (max-width: 1200px) {
    .p-10 .table > tbody > tr > td,
    .p-10 .table > tbody > tr > th,
    .p-10 .table > tfoot > tr > td,
    .p-10 .table > tfoot > tr > th,
    .p-10 .table > thead > tr > td,
    .p-10 .table > thead > tr > th {
        font-size: 11px;
    };
}

.text-center {
    text-align: center !important
}

.text-left {
    text-align: left !important
}

.text-right {
    text-align: right !important
}

.pull-left {
    float: left !important
}

.pull-right {
    float: right !important
}

.f-w-100 {
    font-weight: 100 !important
}

.f-w-200 {
    font-weight: 200 !important
}

.f-w-300 {
    font-weight: 300 !important
}

.f-w-400 {
    font-weight: 400 !important
}

.f-w-500 {
    font-weight: 500 !important
}

.f-w-600 {
    font-weight: 600 !important
}

.f-w-700 {
    font-weight: 700 !important
}

.table-valign-middle td, .table-valign-middle th {
    vertical-align: middle !important
}

.vertical-box {
    display: table;
    width: 100%
}

    .vertical-box .vertical-column {
        display: table-cell;
        vertical-align: top
    }

.panel-expand .vertical-box {
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0
}

    .panel-expand .vertical-box .vertical-column {
        display: table-cell;
        width: 100%
    }

.no-rounded-corner {
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important
}

.rounded-corner {
    -webkit-border-radius: 50% !important;
    -moz-border-radius: 50% !important;
    border-radius: 50% !important
}

.no-border {
    border: 0 !important
}

.border-top-1 {
    border-top: 1px solid #eee !important
}

.border-right-1 {
    border-right: 1px solid #eee !important
}

.border-bottom-1 {
    border-bottom: 1px solid #eee !important
}

.border-left-1 {
    border-left: 1px solid #eee !important
}

.no-box-shadow {
    -webkit-box-shadow: none !important;
    box-shadow: none !important
}

.text-inverse {
    color: #2d353c !important
}

.text-success {
    color: #00acac !important
}

.text-info {
    color: #49b6d6 !important
}

.text-primary {
    color: #348fe2 !important
}

.text-warning {
    color: #f59c1a !important
}

.text-danger {
    color: #ff5b57 !important
}

.text-white {
    color: #fff !important
}

.bg-white {
    background: #fff !important
}

.bg-silver {
    background: #f0f3f4 !important
}

.bg-black {
    background: #2d353c !important
}

.bg-black-darker {
    background: #242a30 !important
}

.bg-black-lighter {
    background: #575d63 !important
}

.bg-grey {
    background: #b6c2c9 !important
}

.bg-grey-darker {
    background: #929ba1 !important
}

.bg-grey-lighter {
    background: #c5ced4 !important
}

.bg-red {
    background: #ff5b57 !important
}

.bg-red-darker {
    background: #cc4946 !important
}

.bg-red-lighter {
    background: #ff7c79 !important
}

.bg-orange {
    background: #f59c1a !important
}

.bg-orange-darker {
    background: #c47d15 !important
}

.bg-orange-lighter {
    background: #f7b048 !important
}

.bg-yellow {
    background: #e3fa3e !important
}

.bg-yellow-darker {
    background: #b6c832 !important
}

.bg-yellow-lighter {
    background: #e9fb65 !important
}

.bg-green {
    background: #00acac !important
}

.bg-green-darker {
    background: #008a8a !important
}

.bg-green-lighter {
    background: #33bdbd !important
}

.bg-blue {
    background: #348fe2 !important
}

.bg-blue-darker {
    background: #2a72b5 !important
}

.bg-blue-lighter {
    background: #5da5e8 !important
}

.bg-aqua {
    background: #49b6d6 !important
}

.bg-aqua-darker {
    background: #3a92ab !important
}

.bg-aqua-lighter {
    background: #6dc5de !important
}

.bg-purple {
    background: #727cb6 !important
}

.bg-purple-darker {
    background: #5b6392 !important
}

.bg-purple-lighter {
    background: #8e96c5 !important
}

.no-bg {
    background: none !important
}

.height-xs {
    height: 150px !important
}

.height-sm {
    height: 300px !important
}

.height-md {
    height: 450px !important
}

.height-lg {
    height: 600px !important
}

.height-full {
    height: 100% !important
}

.width-xs {
    width: 150px !important
}

.width-sm {
    width: 300px !important
}

.width-md {
    width: 450px !important
}

.width-lg {
    width: 600px !important
}

.width-full {
    width: 100% !important
}

.animated {
    -webkit-animation-duration: .6s;
    animation-duration: .6s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

.fade {
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: opacity .3s linear;
    transition: opacity .3s linear
}

    .fade.in {
        filter: alpha(opacity=100)
    }

.ui-sortable .panel-heading {
    cursor: move
}

.ui-sortable.ui-sortable-disabled .panel-heading {
    cursor: default
}

.ui-sortable-placeholder {
    border: 1px dashed #82868b !important;
    visibility: visible !important;
    background: 0 0;
    margin: 0 !important
}

.panel.ui-sortable-placeholder {
    margin: -1px -1px 19px !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important
}

.slimScrollBar {
    width: 5px !important;
    right: 0 !important
}

.gritter-item-wrapper {
    background: none !important;
    overflow: hidden !important;
    -webkit-border-radius: 3px !important;
    -moz-border-radius: 3px !important;
    border-radius: 3px
}

.gritter-bottom, .gritter-item, .gritter-top {
    background: url('../img/transparent/black-0.8.png') !important;
    background: rgba(0,0,0,.8) !important
}

.gritter-close, .gritter-light .gritter-close {
    left: auto !important;
    right: 15px !important;
    top: 0 !important;
    background: #ff5b57 !important;
    width: 16px !important;
    height: 16px !important;
    line-height: 16px !important;
    display: block !important
}

    .gritter-close:before, .gritter-light .gritter-close:before {
        content: '\f00d' !important;
        font-family: FontAwesome !important;
        font-size: 9px !important;
        width: 16px !important;
        height: 16px !important;
        line-height: 16px !important;
        color: #fff !important;
        text-indent: 0 !important;
        position: absolute !important;
        text-align: center !important;
        right: 0 !important;
        top: 0 !important
    }

.gritter-with-image {
    width: 210px !important
}

.gritter-image {
    margin: 2px 10px 0 0 !important
}

.gritter-title {
    font-size: 12px !important;
    line-height: 16px !important;
    padding-bottom: 2px !important;
    font-weight: 400 !important;
    color: #fff !important;
    text-shadow: none !important
}

.gritter-light .gritter-title {
    color: #333 !important;
    font-weight: 600 !important
}

.gritter-item {
    font-family: 'Open Sans' !important;
    color: #aaa !important;
    font-size: 10px !important;
    padding: 2px 15px 5px !important
}

.gritter-light .gritter-bottom, .gritter-light .gritter-item, .gritter-light .gritter-top {
    background: url('../img/transparent/white-0.98.png') !important;
    background: rgba(255,255,255,.98) !important;
    color: #999 !important
}

.dataTables_filter input, .dataTables_length select {
    border: 1px solid #bec0c4;
    background: #edf0f5;
    font-size: 12px;
    padding: 6px 12px;
    line-height: 1.42857143;
    color: #555;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px
}

.dataTables_length select {
    margin-right: 10px
}

.dataTables_filter input {
    border-color: #edf0f5;
    height: 34px;
    margin-left: 10px
}

    .dataTables_filter input:focus {
        outline: 0;
        border-color: #bec0c4;
        -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,.12);
        box-shadow: inset 0 1px 2px rgba(0,0,0,.12)
    }

.datepicker table tr td.today, .datepicker table tr td.today.disabled, .datepicker table tr td.today.disabled:hover, .datepicker table tr td.today:hover {
    background: #eee !important
}

.datepicker table tr td span.active.active, .datepicker table tr td.active.active, .datepicker table tr td.active.disabled.active, .datepicker table tr td.active.disabled:active, .datepicker table tr td.active.disabled:focus, .datepicker table tr td.active.disabled:hover, .datepicker table tr td.active.disabled:hover.active, .datepicker table tr td.active.disabled:hover:active, .datepicker table tr td.active.disabled:hover:focus, .datepicker table tr td.active.disabled:hover:hover, .datepicker table tr td.active:active, .datepicker table tr td.active:focus, .datepicker table tr td.active:hover, .datepicker table tr td.active:hover.active, .datepicker table tr td.active:hover:active, .datepicker table tr td.active:hover:focus, .datepicker table tr td.active:hover:hover, .open .dropdown-toggle.datepicker table tr td.active, .open .dropdown-toggle.datepicker table tr td.active.disabled, .open .dropdown-toggle.datepicker table tr td.active.disabled:hover, .open .dropdown-toggle.datepicker table tr td.active:hover {
    background: #348fe2 !important;
    color: #fff !important
}

.datepicker {
    min-width: 250px !important;
    -webkit-border-radius: 3px !important;
    -moz-border-radius: 3px !important;
    border-radius: 3px !important
}

    .datepicker.dropdown-menu {
        min-width: 250px;
        border: 1px solid #ddd !important;
        -webkit-box-shadow: 0 2px 5px -1px rgba(0,0,0,.2) !important;
        -moz-box-shadow: 0 2px 5px -1px rgba(0,0,0,.2) !important
    }

.datepicker-dropdown:before {
    border-bottom-color: #ddd !important
}

.datepicker-dropdown.datepicker-orient-bottom:before {
    border-top-color: #ddd !important
}

.datepicker table {
    width: 100%
}

.input-daterange .input-group-addon {
    background: #d6d8dd !important;
    border: none !important;
    text-shadow: none !important
}

.irs-from, .irs-single, .irs-to {
    background: #2d353c !important
}

.colorpicker {
    border: 1px solid #ddd !important;
    -webkit-border-radius: 3px !important;
    -moz-border-radius: 3px !important;
    border-radius: 3px !important
}

.colorpicker-alpha, .colorpicker-hue {
    margin-bottom: 0
}

.bootstrap-timepicker .bootstrap-timepicker-widget.dropdown-menu + .form-control {
    -webkit-border-radius: 4px 0 0 4px;
    -moz-border-radius: 4px 0 0 4px;
    border-radius: 4px 0 0 4px
}

.bootstrap-timepicker-widget table td input {
    -webkit-border-radius: 4px !important;
    -moz-border-radius: 4px !important;
    border-radius: 4px !important
}

.bootstrap-timepicker-widget.dropdown-menu.open {
    border: 1px solid #ddd !important;
    max-width: 200px
}

.bootstrap-timepicker-widget table td a:focus, .bootstrap-timepicker-widget table td a:hover {
    background: #348fe2 !important;
    border: none !important;
    color: #fff
}

.ui-widget {
    font-family: 'Open Sans';
    font-size: 12px
}

.ui-autocomplete {
    padding: 5px 0 !important;
    -webkit-box-shadow: 0 2px 5px -1px rgba(0,0,0,.2) !important;
    box-shadow: 0 2px 5px -1px rgba(0,0,0,.2) !important;
    border: 1px solid #ccc !important
}

.ui-state-focus, .ui-state-hover, .ui-widget-content .ui-state-focus, .ui-widget-content .ui-state-hover, .ui-widget-header .ui-state-focus, .ui-widget-header .ui-state-hover {
    border: none !important;
    background: #348fe2 !important;
    color: #fff !important;
    margin: 0 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important
}

.ui-menu .ui-menu-item a {
    padding: 5px 10px
}

.combobox-container {
    display: table;
    border-collapse: separate;
    width: 100%
}

    .combobox-container .uneditable-input, .combobox-container input {
        border: 1px solid #edf0f5;
        background: #edf0f5;
        font-size: 12px;
        display: block;
        width: 100%;
        height: 34px;
        padding: 6px 12px;
        line-height: 1.42857143;
        color: #555;
        border-radius: 4px 0 0 4px !important;
        -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
        transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s
    }

        .combobox-container .uneditable-input:focus, .combobox-container input:focus {
            outline: 0;
            border-color: #d8dce2;
            -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,.12);
            box-shadow: inset 0 1px 2px rgba(0,0,0,.12)
        }

    .combobox-container .add-on, .combobox-container input {
        display: table-cell !important
    }

    .combobox-container .add-on {
        width: 1% !important;
        padding: 5px 10px !important;
        white-space: nowrap;
        float: none !important;
        background: #d6d8dd !important;
        color: #555 !important
    }

.btn .combobox-clear {
    margin: 0 !important;
    width: 8px !important;
    position: relative;
    opacity: 1;
    filter: alpha(opacity=100)
}

.combobox-container .icon-remove:before {
    content: '\f00d';
    font-family: FontAwesome;
    font-size: 14px;
    font-style: normal;
    position: absolute;
    right: -2px;
    top: 1px;
    text-shadow: none
}

body .bootstrap-select .btn:focus {
    outline: 0 !important
}

.tagit.ui-widget {
    border: 1px solid #edf0f5 !important;
    background: #edf0f5 !important
}

ul.tagit input[type=text] {
    background: #edf0f5 !important;
    width: auto !important
}

ul.tagit li.tagit-choice-editable {
    background: #b6c2c9 !important;
    border: none !important;
    color: #fff !important;
    -webkit-border-radius: 3px !important;
    -moz-border-radius: 3px !important;
    border-radius: 3px !important;
    padding-right: 24px !important
}

ul.tagit.inverse li.tagit-choice-editable {
    background: #2d353c !important
}

ul.tagit.white li.tagit-choice-editable {
    background: #fff !important;
    border: 1px solid #ddd !important;
    color: #000 !important
}

ul.tagit.primary li.tagit-choice-editable {
    background: #348fe2 !important
}

ul.tagit.info li.tagit-choice-editable {
    background: #49b6d6 !important
}

ul.tagit.success li.tagit-choice-editable {
    background: #00acac !important
}

ul.tagit.warning li.tagit-choice-editable {
    background: #f59c1a !important
}

ul.tagit.danger li.tagit-choice-editable {
    background: #ff5b57 !important
}

ul.tagit li.tagit-choice .tagit-close {
    right: 2px !important;
    line-height: 22px !important;
    text-align: center !important;
    top: 0 !important;
    margin: 0 !important
}

    ul.tagit li.tagit-choice .tagit-close:focus, ul.tagit li.tagit-choice .tagit-close:hover {
        text-decoration: none !important
    }

        ul.tagit li.tagit-choice .tagit-close:focus .ui-icon-close:before, ul.tagit li.tagit-choice .tagit-close:hover .ui-icon-close:before {
            color: #666;
            color: rgba(0,0,0,.4)
        }

ul.tagit.inverse li.tagit-choice .tagit-close:focus .ui-icon-close:before, ul.tagit.inverse li.tagit-choice .tagit-close:hover .ui-icon-close:before {
    color: #666;
    color: rgba(255,255,255,.4)
}

.tagit .ui-icon-close {
    background: none !important;
    height: 22px !important;
    width: 16px !important;
    text-indent: 0 !important
}

.tagit.white .ui-icon-close:before {
    color: #000
}

.tagit .ui-icon-close:before {
    content: '\f00d';
    font-family: FontAwesome;
    font-size: 10px;
    color: #fff
}

.parsley-errors-list {
    padding: 0 !important;
    list-style-type: none !important;
    margin: 5px 0 0 !important;
    color: #e5603b;
    font-size: 12px !important;
    line-height: inherit !important
}

.parsley-error, .parsley-success {
    color: #333 !important
}

.parsley-error {
    background: #ffdedd !important;
    border-color: #ff5b57 !important
}

.parsley-success {
    background: #cee !important;
    border-color: #00acac !important
}

    .parsley-error:focus, .parsley-success:focus {
        color: #333 !important;
        box-shadow: inset 0 1px 2px rgba(0,0,0,.3) !important;
        -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,.3) !important
    }

.panel-form .cke_chrome {
    border: none;
    box-shadow: none;
    -webkit-box-shadow: none
}

.panel-form .wysihtml5-toolbar {
    padding: 15px 15px 5px
}

.panel-form .wysihtml5-sandbox {
    -webkit-border-radius: 0 0 4px 4px !important;
    -moz-border-radius: 0 0 4px 4px !important;
    border-radius: 0 0 4px 4px !important
}

.bwizard-steps {
    font-size: 14px !important;
    border-radius: 3px !important;
    overflow: hidden !important;
    display: table !important;
    width: 100% !important
}

    .bwizard-steps li:after, .bwizard-steps li:before {
        border: none !important
    }

    .bwizard-steps li {
        float: none !important;
        display: table-cell !important;
        padding: 15px !important;
        line-height: 20px !important;
        background: #f0f3f4 !important;
        -webkit-border-radius: 0 !important;
        -moz-border-radius: 0 !important;
        border-radius: 0 !important
    }

        .bwizard-steps li:focus, .bwizard-steps li:hover {
            background: #d3dadf !important
        }

    .bwizard-steps.clickable li:focus:not(.active) a, .bwizard-steps.clickable li:hover:not(.active) a {
        color: #333 !important
    }

    .bwizard-steps li small {
        font-size: 11px !important;
        color: #999 !important;
        display: block !important;
        line-height: 14px !important
    }

    .bwizard-steps li a {
        margin-left: 30px !important;
        display: block !important;
        text-decoration: none !important
    }

    .bwizard-steps li.active small {
        color: #b2e6e6 !important
    }

    .bwizard-steps .label {
        float: left !important;
        padding: 0 !important;
        height: 20px !important;
        width: 20px !important;
        line-height: 20px !important;
        text-align: center !important;
        margin-right: 10px !important;
        top: 0 !important;
        background: #6d7479 !important;
        -webkit-border-radius: 50% !important;
        -moz-border-radius: 50% !important;
        border-radius: 50% !important
    }

    .bwizard-steps li.active, .bwizard-steps li.active:focus, .bwizard-steps li.active:hover {
        background: #00acac !important
    }

        .bwizard-steps li.active .label {
            background: url('../img/transparent/black-0.2.png') !important;
            background: rgba(0,0,0,.2) !important
        }

.external-event {
    padding: 15px !important;
    margin-bottom: 5px !important;
    color: #fff !important;
    color: rgba(255,255,255,.7) !important;
    -webkit-border-radius: 3px !important;
    -moz-border-radius: 3px !important;
    border-radius: 3px !important
}

.calendar-event .external-event h5 {
    color: #fff !important;
    margin: 0 0 5px !important
}

.calendar-event .external-event p {
    margin: 0 !important;
    line-height: 16px !important;
    font-weight: 300 !important
}

.fc-content {
    clear: none !important
}

.fc-state-highlight {
    background: #f0f3f4 !important
}

.fc-widget-content, .fc-widget-header {
    border-color: #e2e7eb !important
}

.fc-widget-header {
    color: #242a30 !important;
    font-weight: 600 !important;
    padding: 3px 15px !important
}

.fc-grid .fc-day-number {
    padding: 3px 5px !important
}

.fc-content .fc-event {
    border: none !important;
    padding: 5px !important;
    text-align: center !important;
    background: #2d353c
}

.fc-event-time {
    font-size: 14px !important;
    margin-right: 5px !important
}

.fc-event .fc-event-title {
    font-size: 14px !important;
    display: block !important
}

    .fc-event .fc-event-title small {
        display: block !important;
        font-size: 12px !important;
        font-weight: 300 !important;
        line-height: 16px !important;
        color: #ccc !important;
        color: rgba(255,255,255,.8) !important
    }

.fc-event .fc-event-icons {
    font-size: 18px !important;
    display: block !important
}

.fc-event-container a:focus, .fc-event-container a:hover {
    color: #fff !important;
    text-decoration: underline !important
}

.fc-state-default {
    background: #fff !important;
    border: 1px solid #ccc !important;
    line-height: 1.42857143 !important;
    padding: 6px 12px !important;
    color: #333 !important;
    font-weight: 400 !important;
    height: auto !important
}

.fc-header .fc-button {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    margin-bottom: 15px !important
}

    .fc-header .fc-button:not(.fc-state-disabled):focus, .fc-header .fc-button:not(.fc-state-disabled):hover {
        background: #eee !important
    }

    .fc-header .fc-button.fc-state-active, .fc-header .fc-button.fc-state-down {
        background: #eee !important;
        -webkit-box-shadow: inset 0 3px 5px rgba(0,0,0,.125) !important;
        box-shadow: inset 0 3px 5px rgba(0,0,0,.125) !important
    }

.fc-text-arrow {
    font-size: 14px !important;
    line-height: 16px !important
}

.fc-header-title h2 {
    line-height: 31px !important;
    font-size: 24px !important
}

.jvectormap-zoomin, .jvectormap-zoomout {
    width: 16px !important;
    height: 16px !important
}

.jvectormap-label {
    padding: 3px 6px !important;
    border: none !important;
    background: url('../img/transparent/black-0.6.png') !important;
    background: rgba(0,0,0,.6) !important
}

.legendColorBox > div {
    margin-right: 5px !important;
    border-radius: 5px !important;
    margin-top: 1px !important;
    border: none !important
}

.legend > table tr td.legendColorBox {
    padding-left: 5px !important
}

.legend > table tr td {
    padding: 4px 0 5px !important
}

.legend > div {
    border-radius: 4px !important;
    background: #000 !important
}

.legend > table tr td.legendLabel {
    padding-right: 10px !important;
    color: #fff
}

.legend > table tr + tr td {
    padding-top: 0 !important
}

.legendColorBox > div > div {
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    border-radius: 5px !important;
    border-width: 4px !important
}

.legendLabel, .tickLabel {
    font-size: 11px !important
}

.tickLabel {
    color: #707478 !important
}

.flot-tooltip {
    position: absolute;
    display: none;
    border: 0;
    padding: 6px 10px 7px;
    color: #fff;
    font-size: 11px;
    background: url('../img/transparent/black-0.8.png');
    background-color: rgba(0,0,0,.8);
    border-radius: 5px;
    opacity: 1;
    filter: alpha(opacity=100);
    z-index: 10000 !important
}

.jqstooltip {
    border: none !important;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    width: auto !important;
    height: auto !important;
    background: url('../img/transparent/black-0.8.png') !important;
    background: rgba(0,0,0,.8) !important
}

.datepicker-full-width .datepicker-inline {
    width: 100%
}

.result-list:after, .result-list:before {
    content: '';
    display: table;
    clear: both
}

.result-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%
}

    .result-list > li:after, .result-list > li:before {
        content: '';
        display: table;
        clear: both
    }

    .result-list > li {
        background: #fff;
        box-shadow: 0 1px #ccc;
        border-radius: 3px;
        overflow: hidden
    }

        .result-list > li + li {
            margin-top: 10px
        }

        .result-list > li > div {
            display: table-cell;
            vertical-align: top;
            padding: 20px;
            position: relative
        }

    .result-list .result-image {
        width: 240px;
        padding: 0;
        overflow: hidden;
        background: #2d353c;
        vertical-align: middle
    }

        .result-list .result-image a {
            display: block
        }

            .result-list .result-image a:focus, .result-list .result-image a:hover {
                opacity: .8;
                filter: alpha(opacity=80)
            }

        .result-list .result-image img {
            width: 100%
        }

    .result-list .title {
        margin: 0 0 8px;
        font-size: 20px;
        line-height: 24px
    }

        .result-list .title a {
            color: #333
        }

    .result-list .location {
        color: #999
    }

    .result-list .desc {
        margin-bottom: 20px;
        max-height: 32px;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 16px
    }

    .result-list .btn-row a {
        color: #666;
        font-size: 20px;
        line-height: 14px;
        opacity: .2
    }

        .result-list .btn-row a:focus, .result-list .btn-row a:hover {
            opacity: 1;
            filter: alpha(opacity=100)
        }

        .result-list .btn-row a + a {
            margin-left: 10px
        }

    .result-list .result-price {
        width: 240px;
        font-size: 28px;
        text-align: center;
        border-left: 1px dashed #ddd;
        padding-top: 30px
    }

        .result-list .result-price small {
            display: block;
            font-size: 12px;
            color: #999
        }

        .result-list .result-price .btn {
            margin-top: 30px
        }

.media-messaging .media:first-child {
    padding-top: 0
}

.media-messaging .media:last-child {
    padding-bottom: 0
}

.media-messaging .media {
    padding: 15px
}

    .media-messaging .media p {
        margin: 0
    }

    .media-messaging .media + .media {
        margin-top: 0;
        padding-top: 15px
    }

.widget {
    border-radius: 3px;
    margin-bottom: 20px;
    color: #fff;
    padding: 15px;
    overflow: hidden
}

    .widget.widget-state {
        position: relative
    }

.widget-state .state-info h4 {
    font-size: 12px;
    margin: 5px 0;
    color: #fff
}

.widget-state .state-icon {
    font-size: 42px;
    height: 56px;
    width: 56px;
    text-align: center;
    line-height: 56px;
    margin-left: 15px;
    color: #fff;
    position: absolute;
    right: 15px;
    top: 15px;
    opacity: .2;
    filter: alpha(opacity=20)
}

.widget-state .state-info p {
    font-size: 24px;
    font-weight: 300;
    margin-bottom: 0
}

.widget-state .state-link a {
    display: block;
    margin: 15px -15px -15px;
    padding: 7px 15px;
    background: url('../img/transparent/black-0.4.png');
    background: rgba(0,0,0,.4);
    text-align: right;
    color: #ddd;
    font-weight: 300;
    text-decoration: none
}

    .widget-state .state-link a:focus, .widget-state .state-link a:hover {
        background: url('../img/transparent/black-0.6.png');
        background: rgba(0,0,0,.6);
        color: #fff
    }

.todolist {
    list-style-type: none;
    margin: 0;
    padding: 0
}

    .todolist > li > a {
        display: block;
        color: #333
    }

        .todolist > li > a:focus, .todolist > li > a:hover {
            background: #fafafa;
            text-decoration: none
        }

            .todolist > li > a:focus .todolist-input i:before, .todolist > li > a:hover .todolist-input i:before {
                content: '\f14a';
                opacity: .4;
                filter: alpha(opacity=40);
                color: #2d353c
            }

    .todolist > li.active > a .todolist-input i:before, .todolist > li.active > a:focus .todolist-input i:before, .todolist > li.active > a:hover .todolist-input i:before {
        content: '\f14a';
        color: #00acac;
        opacity: 1;
        filter: alpha(opacity=100)
    }

    .todolist > li.active > a.todolist-title {
        text-decoration: line-through
    }

    .todolist > li {
        border-bottom: 1px solid #ddd
    }

.todolist-input i {
    display: block;
    width: 14px;
    text-align: center
}

.todolist-input, .todolist-title {
    display: table-cell;
    padding: 10px
}

.todolist-title {
    padding-right: 15px
}

.todolist-input {
    text-align: center;
    font-size: 14px;
    border-right: 1px solid #ddd;
    padding-left: 15px
}

.page-sidebar-minified .map {
    left: 60px
}

.map {
    position: absolute;
    top: 54px;
    bottom: 0;
    left: 220px;
    right: 0;
    z-index: 0
}

    .map .jvectormap-zoomin, .map .jvectormap-zoomout {
        width: 20px !important;
        height: 20px !important;
        font-size: 14px;
        line-height: 14px;
        left: 15px
    }

    .map .jvectormap-zoomin {
        top: 60px
    }

    .map .jvectormap-zoomout {
        top: 90px
    }

.map-float-table {
    position: absolute;
    right: 25px;
    bottom: 25px;
    background: url('../img/transparent/black-0.6.png');
    background: rgba(0,0,0,.6)
}

.map-content {
    padding: 0 25px;
    z-index: 100;
    position: relative
}

.invoice > div:not(.invoice-footer) {
    margin-bottom: 20px
}

.invoice {
    background: #fff;
    padding: 20px
}

.invoice-company {
    font-size: 20px
}

.invoice-header {
    margin: 0 -20px;
    background: #f0f3f4;
    padding: 20px
}

.invoice-date, .invoice-from, .invoice-to {
    display: table-cell;
    width: 1%
}

.invoice-from, .invoice-to {
    padding-right: 20px
}

    .invoice-date .date, .invoice-from strong, .invoice-to strong {
        font-size: 16px;
        font-weight: 600
    }

.invoice-date {
    text-align: right;
    padding-left: 20px
}

.invoice-price {
    background: #f0f3f4;
    display: table;
    width: 100%
}

    .invoice-price .invoice-price-left, .invoice-price .invoice-price-right {
        display: table-cell;
        padding: 20px;
        font-size: 20px;
        font-weight: 600;
        width: 75%;
        position: relative;
        vertical-align: middle
    }

        .invoice-price .invoice-price-left .sub-price {
            display: table-cell;
            vertical-align: middle;
            padding: 0 20px
        }

    .invoice-price small {
        font-size: 12px;
        font-weight: 400;
        display: block
    }

    .invoice-price .invoice-price-row {
        display: table;
        float: left
    }

    .invoice-price .invoice-price-right {
        width: 25%;
        background: #2d353c;
        color: #fff;
        font-size: 28px;
        text-align: right;
        vertical-align: bottom;
        font-weight: 300
    }

        .invoice-price .invoice-price-right small {
            display: block;
            opacity: .6;
            filter: alpha(opacity=60);
            position: absolute;
            top: 10px;
            left: 10px;
            font-size: 12px
        }

.invoice-footer {
    border-top: 1px solid #ddd;
    padding-top: 10px;
    font-size: 10px
}

.invoice-note {
    color: #999;
    margin-top: 80px;
    font-size: 85%
}

.login {
    margin: 168px 0
}

    .login label {
        color: #707478
    }

    .login .login-header {
        position: absolute;
        top: -80px;
        left: 50%;
        right: 0;
        width: 450px;
        padding: 0 40px;
        margin-left: -225px;
        font-weight: 300
    }

        .login .login-header .brand {
            padding: 0;
            font-size: 28px
        }

            .login .login-header .brand .logo {
                border: 14px solid transparent;
                border-color: #4DCACA #31A3A3 #1D8888;
                width: 28px;
                height: 28px;
                position: relative;
                font-size: 0;
                margin-right: 10px;
                top: -9px
            }

            .login .login-header .brand small {
                font-size: 14px;
                display: block
            }

        .login .login-header .icon {
            position: absolute;
            right: 40px;
            top: -2px;
            opacity: .1;
            filter: alpha(opacity=10)
        }

            .login .login-header .icon i {
                font-size: 70px
            }

    .login .login-content {
        padding: 30px 40px;
        color: #999;
        width: 450px;
        margin: 0 auto
    }

.gallery-option-set {
    display: block;
    margin-bottom: -5px
}

    .gallery-option-set .btn {
        margin: 0 5px 5px 0
    }

.gallery {
    margin: 0 -10px
}

    .gallery .image {
        width: 25%;
        display: block;
        margin-right: -.5%;
        overflow: hidden;
        padding: 10px
    }

        .gallery .image img {
            width: 100%;
            height: 200px;
            -webkit-border-radius: 3px 3px 0 0;
            -moz-border-radius: 3px 3px 0 0;
            border-radius: 3px 3px 0 0
        }

    .gallery .image-inner {
        position: relative;
        background: #fff;
        -webkit-border-radius: 3px 3px 0 0;
        -moz-border-radius: 3px 3px 0 0;
        border-radius: 3px 3px 0 0
    }

    .gallery .image a {
        -webkit-transition: all .2s linear;
        -moz-transition: all .2s linear;
        transition: all .2s linear
    }

        .gallery .image a:focus, .gallery .image a:hover {
            opacity: .8;
            filter: alpha(opacity=80)
        }

    .gallery .image-caption {
        position: absolute;
        top: 15px;
        left: 0;
        background: url('../img/transparent/black-0.6.png');
        background: rgba(0,0,0,.6);
        color: #fff;
        padding: 5px 15px;
        margin: 0
    }

    .gallery .image-info {
        background: #fff;
        padding: 15px;
        -webkit-border-radius: 0 0 3px 3px;
        -moz-border-radius: 0 0 3px 3px;
        border-radius: 0 0 3px 3px
    }

        .gallery .image-info .title {
            margin: 0 0 10px;
            line-height: 18px;
            font-size: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis
        }

        .gallery .image-info .rating {
            line-height: 20px;
            margin: 0 0 3px
        }

        .gallery .image-info .desc {
            line-height: 16px;
            font-size: 12px;
            height: 48px;
            overflow: hidden
        }

    .gallery .rating span.star {
        font-family: FontAwesome;
        display: inline-block
    }

        .gallery .rating span.star:before {
            content: "\f005";
            color: #999
        }

        .gallery .rating span.star.active:before {
            color: #FF8500
        }

.nav-inbox > li > a {
    padding: 8px 15px
}

    .nav-inbox > li > a i {
        font-size: 14px
    }

.table.table-email {
    line-height: 20px;
    margin-bottom: 0
}

.email-footer {
    border-top: 1px solid #ccc;
    padding: 15px 8px;
    line-height: 26px
}

.table.table-email .email-header-link {
    color: #333
}

.table.table-email .email-select {
    width: 20px;
    font-size: 14px
}

    .table.table-email .email-select a {
        color: #999
    }

.table.table-email .email-btn {
    color: #ccc;
    font-size: 14px;
    margin-right: 15px
}

.table.table-email .email-sender {
    padding-right: 25px
}

.table.table-email .email-btn + .email-btn {
    margin-left: -12px
}

.table.table-email .email-btn:focus, .table.table-email .email-btn:hover {
    color: #999
}

.table.table-email tr:hover td {
    background: #fafafa
}

.table.table-email tr.active td.email-select i:before, .table.table-email tr.active th.email-select i:before {
    content: '\f14a'
}

.table.table-email thead tr.active th {
    background: 0 0
}

.email-btn-row {
    margin-bottom: 15px
}

    .email-btn-row .btn + .btn {
        margin-left: 5px
    }

.email-content {
    background: #fff;
    padding: 15px
}

.error {
    margin: 0 auto;
    text-align: center
}

.error-code, .error-content {
    position: absolute;
    left: 0;
    right: 0
}

.error-code {
    bottom: 60%;
    color: #2d353c;
    font-size: 96px;
    line-height: 100px
}

.error-content {
    top: 40%;
    bottom: 0;
    padding-top: 15px;
    background: #2d353c
}

.error-message {
    color: #fff;
    font-size: 24px
}

.error-desc {
    font-size: 12px;
    color: #647788
}

.timeline {
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: relative
}

    .timeline:before {
        content: '';
        position: absolute;
        top: 5px;
        bottom: 5px;
        width: 5px;
        background: #2d353c;
        left: 20%;
        margin-left: -2.5px
    }

    .timeline > li {
        position: relative;
        min-height: 50px
    }

        .timeline > li + li {
            margin-top: 40px
        }

    .timeline .timeline-time {
        position: absolute;
        left: 0;
        width: 15%;
        text-align: right;
        padding-top: 7px
    }

        .timeline .timeline-time .date, .timeline .timeline-time .time {
            display: block
        }

        .timeline .timeline-time .date {
            line-height: 18px;
            font-size: 14px
        }

        .timeline .timeline-time .time {
            line-height: 28px;
            font-size: 24px;
            color: #242a30
        }

    .timeline .timeline-icon {
        left: 15%;
        position: absolute;
        width: 10%;
        text-align: center;
        top: 5px
    }

        .timeline .timeline-icon a {
            text-decoration: none;
            width: 50px;
            height: 50px;
            display: inline-block;
            -webkit-border-radius: 50px;
            -moz-border-radius: 50px;
            border-radius: 50px;
            background: #575d63;
            line-height: 40px;
            color: #fff;
            font-size: 14px;
            border: 5px solid #2d353c;
            transition: background .2s linear;
            -moz-transition: background .2s linear;
            -webkit-transition: background .2s linear
        }

            .timeline .timeline-icon a:focus, .timeline .timeline-icon a:hover {
                background: #00acac
            }

    .timeline .timeline-body {
        margin-left: 25%;
        margin-right: 15%;
        background: #fff;
        position: relative;
        padding: 20px 30px;
        border-radius: 4px;
        -moz-border-radius: 4px;
        -webkit-border-radius: 4px
    }

        .timeline .timeline-body:before {
            content: '';
            display: block;
            position: absolute;
            border: 10px solid transparent;
            border-right-color: #fff;
            left: -20px;
            top: 20px
        }

.timeline-header {
    padding-bottom: 10px;
    border-bottom: 1px solid #e2e7eb;
    line-height: 30px
}

    .timeline-header .userimage {
        float: left;
        width: 34px;
        height: 34px;
        -webkit-border-radius: 40px;
        -moz-border-radius: 40px;
        border-radius: 40px;
        overflow: hidden;
        margin: -2px 10px -2px 0
    }

    .timeline-header .username {
        font-size: 16px;
        font-weight: 600
    }

        .timeline-header .username, .timeline-header .username a {
            color: #00acac
        }

.timeline img {
    max-width: 100%;
    display: block
}

.timeline-content {
    font-size: 14px
}

    .timeline-content + .timeline-footer, .timeline-header + .timeline-content, .timeline-header + .timeline-footer {
        margin-top: 20px
    }

    .timeline-content:after, .timeline-content:before {
        content: '';
        display: table;
        clear: both
    }

.timeline-title {
    margin-top: 0
}

.timeline-footer {
    margin: -20px -30px;
    padding: 20px 30px;
    background: #e8ecf1;
    -webkit-border-radius: 0 0 4px 4px;
    -moz-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px
}

    .timeline-footer a:not(.btn) {
        color: #575d63
    }

        .timeline-footer a:not(.btn):focus, .timeline-footer a:not(.btn):hover {
            color: #2d353c
        }

.coming-soon .brand {
    line-height: 40px;
    font-size: 28px;
    font-weight: 300;
    margin-bottom: 50px;
    color: #fff;
    text-align: center
}

    .coming-soon .brand .logo {
        border: 16px solid transparent;
        border-color: #4DCACA #31A3A3 #1D8888;
        margin-right: 5px;
        position: relative;
        width: 0;
        height: 0;
        display: inline-block;
        vertical-align: middle;
        top: -4px
    }

.coming-soon .coming-soon-header .brand, .coming-soon .coming-soon-header .desc, .coming-soon .coming-soon-header .timer {
    position: relative;
    z-index: 1020
}

.coming-soon .coming-soon-header {
    background: url('../img/coming-soon.jpg');
    padding: 100px 0 50px;
    position: relative
}

    .coming-soon .coming-soon-header:before {
        background: #000
    }

    .coming-soon .coming-soon-header .bg-cover {
        background: #000;
        opacity: .6;
        filter: alpha(opacity=60);
        position: absolute;
        right: 0;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 0
    }

    .coming-soon .coming-soon-header .desc {
        text-align: center;
        font-size: 14px;
        font-weight: 300;
        color: #fff
    }

    .coming-soon .coming-soon-header .timer {
        margin-bottom: 50px;
        padding: 10px 0
    }

        .coming-soon .coming-soon-header .timer:after, .coming-soon .coming-soon-header .timer:before {
            content: '';
            display: table;
            clear: both
        }

.coming-soon .timer .is-countdown {
    background: 0 0;
    border: none;
    width: 980px;
    margin: 0 auto
}

    .coming-soon .timer .is-countdown .countdown-section {
        padding: 0 10px
    }

        .coming-soon .timer .is-countdown .countdown-section + .countdown-section {
            border-left: 1px solid #fff;
            border-left: 1px solid rgba(255,255,255,.3)
        }

.coming-soon .timer .countdown-amount {
    font-size: 36px;
    color: #fff
}

.coming-soon .timer .countdown-period {
    font-size: 14px;
    color: #999
}

.coming-soon a {
    color: #00acac
}

.coming-soon .coming-soon-content {
    color: #333;
    text-align: center;
    padding: 25px 0
}

    .coming-soon .coming-soon-content .desc {
        margin-bottom: 25px;
        font-size: 14px
    }

    .coming-soon .coming-soon-content .input-group {
        width: 480px;
        margin: 0 auto
    }

.theme-panel {
    position: fixed;
    right: -175px;
    top: 150px;
    z-index: 1020;
    background: #fff;
    padding: 15px;
    box-shadow: 0 0 2px rgba(0,0,0,.4);
    -webkit-box-shadow: 0 0 2px rgba(0,0,0,.4);
    -moz-box-shadow: 0 0 2px rgba(0,0,0,.4);
    width: 175px;
    -webkit-transition: right .2s linear;
    -moz-transition: right .2s linear;
    transition: right .2s linear
}

    .theme-panel.active {
        right: 0
    }

    .theme-panel .theme-list {
        list-style-type: none;
        margin: 0;
        padding: 0
    }

        .theme-panel .theme-list + .theme-list {
            margin-top: 10px
        }

        .theme-panel .theme-list > li {
            float: left
        }

            .theme-panel .theme-list > li + li {
                margin-left: 5px
            }

            .theme-panel .theme-list > li > a {
                text-decoration: none
            }

            .theme-panel .theme-list > li.active > a {
                position: relative
            }

                .theme-panel .theme-list > li.active > a:before {
                    content: '\f00c';
                    font-family: FontAwesome;
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    font-size: 14px;
                    color: #fff;
                    opacity: .4;
                    filter: alpha(opacity=40);
                    text-align: center
                }

                .theme-panel .theme-list > li.active > a.bg-white:before {
                    color: #666
                }

            .theme-panel .theme-list > li > a {
                width: 20px;
                height: 20px;
                border-radius: 3px;
                display: block;
                -webkit-transition: all .2s linear;
                -moz-transition: all .2s linear;
                transition: all .2s linear
            }

                .theme-panel .theme-list > li > a:focus, .theme-panel .theme-list > li > a:hover {
                    opacity: .6;
                    filter: alpha(opacity=60)
                }

    .theme-panel .theme-collapse-btn {
        position: absolute;
        left: -30px;
        top: 10%;
        margin-top: -15px;
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 18px;
        color: #333;
        background: #fff;
        border-radius: 4px 0 0 4px;
        text-align: center;
        box-shadow: -1px 0 3px -1px rgba(0,0,0,.4);
        -webkit-box-shadow: -1px 0 3px -1px rgba(0,0,0,.4);
        -moz-box-shadow: -1px 0 3px -1px rgba(0,0,0,.4)
    }

    .theme-panel .theme-panel-content {
        margin: -15px;
        padding: 15px;
        background: #fff;
        position: relative;
        z-index: 1020
    }

    .theme-panel.active .theme-collapse-btn .fa:before {
        content: '\f00d'
    }

    .theme-panel .theme-list a.bg-white {
        border: 1px solid #e2e7eb
    }

    .theme-panel .control-label {
        line-height: 30px
    }

        .theme-panel .control-label.double-line {
            line-height: 15px
        }

    .theme-panel .divider {
        height: 1px;
        margin: 15px 0;
        background: #f2f2f2
    }

.slider-wrapper {
    padding: 15px 30px !important;
    margin-bottom: 10px !important
}

    .slider-wrapper.slider-without-range {
        padding: 15px 0 !important
    }

    .slider-wrapper.slider-vertical {
        padding-left: 15px !important;
        padding-right: 15px !important
    }

.range-max, .range-min {
    color: #242a30 !important
}

.range-handle {
    cursor: pointer !important
}

.range-quantity {
    background: #00acac !important
}

.red .range-quantity {
    background: #ff5b57 !important
}

.blue .range-quantity {
    background: #348fe2 !important
}

.purple .range-quantity {
    background: #727cb6 !important
}

.orange .range-quantity {
    background: #f59c1a !important
}

.black .range-quantity {
    background: #2d353c !important
}

.datetimepicker table tr span.active, .datetimepicker table tr td.active {
    background: #348fe2 !important;
    color: #fff !important
}

.datetimepicker table {
    width: 100%
}

.datetimepicker td, .datetimepicker th {
    padding: 0 5px !important;
    width: 30px !important;
    height: 30px !important
}

.wysihtml5-sandbox {
    width: 100% !important
}

.twitter-typeahead .tt-hint {
    line-height: 22px;
    padding: 5px 11px;
    opacity: .6;
    filter: alpha(opacity=60)
}

.tt-suggestion.tt-is-under-cursor {
    background: #edf0f5;
    color: #222
}

.select2-container.form-control .select2-choice {
    background: none !important;
    margin: -6px -12px !important;
    padding: 6px 12px !important;
    line-height: 20px !important;
    border: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none;
    height: 32px !important
}

.select2-container.form-control.input-sm .select2-choice {
    height: 30px !important
}

.select2-container .select2-choice .select2-arrow {
    background: none !important;
    border-left: 1px solid #e2e7eb !important
}

.select2-container.form-control .select2-choice abbr {
    top: 10px !important
}

.select2-container.form-control.input-sm .select2-choice abbr {
    top: 9px !important
}

.select2-container .select2-choice .select2-arrow b {
    background-position: 0 3px !important
}

.select2-container.form-control.input-sm .select2-choice .select2-arrow b {
    background-position: 0 2px !important
}

.select2-drop-active {
    border-color: #e2e7eb !important;
    -webkit-box-shadow: 0 2px 2px -1px rgba(0,0,0,.2) !important;
    box-shadow: 0 2px 2px -1px rgba(0,0,0,.2) !important
}

.select2-drop.select2-drop-above {
    -webkit-box-shadow: 0 -2px 2px -1px rgba(0,0,0,.2) !important;
    box-shadow: 0 -2px 2px -1px rgba(0,0,0,.2) !important
}

.select2-search input {
    border-color: #e2e7eb !important;
    -webkit-border-radius: 4px !important;
    -moz-border-radius: 4px !important;
    border-radius: 4px !important
}

.select2-drop {
    border-color: #e2e7eb !important;
    padding-top: 5px !important;
    border-top: 1px solid #e2e7eb !important
}

.select2-results .select2-highlighted {
    background: #edf0f5 !important;
    color: #222 !important
}

.select2-container.select2-container-multi.form-control {
    height: auto !important;
    width: 240px !important
}

    .select2-container.select2-container-multi.form-control .select2-choices {
        margin: -6px -12px !important;
        border: none !important;
        box-shadow: none !important;
        min-height: 22px !important;
        height: auto !important;
        background: none !important
    }

.select2-container-multi .select2-choices .select2-search-choice {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border-color: #ddd !important;
    background: #fff !important
}

.login-v2 {
    background: url('../img/transparent/black-0.4.png');
    background: rgba(0,0,0,.4);
    color: #ccc;
    width: 450px;
    margin: 168px auto;
    position: relative;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px
}

    .login-v2 .login-content {
        padding: 30px
    }

.login.login-v2 label {
    color: #ccc
}

.login.login-v2 .login-header, .login.login-v2 a {
    color: #fff
}

    .login.login-v2 .login-header .icon {
        opacity: .4;
        filter: alpha(opacity=40)
    }

.login-v2 .form-control {
    background: url('../img/transparent/black-0.4.png');
    background: rgba(0,0,0,.4);
    border: none;
    color: #fff
}



.login-cover, .login-cover-bg, .login-cover-image {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0
}

    .login-cover-image img {
        max-width: 100%;
        top: 0;
        left: 0;
        position: absolute
    }

.login-cover-bg {
    background: #666;
    background: -moz-radial-gradient(center,ellipse cover,#666 0,#000 100%);
    background: -webkit-gradient(radial,center center,0,center center,100%,color-stop(0%,#666),color-stop(100%,#000));
    background: -webkit-radial-gradient(center,ellipse cover,#666 0,#000 100%);
    background: -o-radial-gradient(center,ellipse cover,#666 0,#000 100%);
    background: -ms-radial-gradient(center,ellipse cover,#666 0,#000 100%);
    background: radial-gradient(ellipse at center,#666 0,#000 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#666666', endColorstr='#000000', GradientType=1);
    opacity: .6;
    filter: alpha(opacity=60)
}


.login-bg-list {
    position: absolute;
    right: 20px;
    bottom: 20px;
    z-index: 1020;
    list-style-type: none;
    margin: 0;
    padding: 0 0 0 20px
}

    .login-bg-list > li {
        width: 80px;
        height: 60px;
        overflow: hidden;
        float: left;
        transition: all .2s linear;
        -webkit-transition: all .2s linear
    }

        .login-bg-list > li a {
            display: block;
            height: 60px
        }

        .login-bg-list > li.active {
            outline: 3px solid #fff;
            z-index: 1020;
            position: relative
        }

        .login-bg-list > li img {
            max-height: 100%
        }

/****** STYLE GERAL ******/
/*add erro na validação de formulario*/

.validError {
    color: #424E66;
    background-color: #FFFFFF;
    border: 2px solid #DC393A !important;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(209, 236, 255);
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScxMicgaGVpZ2h0PScxMicgZmlsbD0nI0RDMzkzQScgc3Ryb2tlPScjRkZGRkZGJyB2aWV3Qm94PScwIDAgMTIgMTInPjxjaXJjbGUgY3g9JzYnIGN5PSc2JyByPSc2Jy8+PHBhdGggc3Ryb2tlLWxpbmVqb2luPSdyb3VuZCcgZD0nTTUuOCAzLjZoLjRMNiA2LjV6Jy8+PGNpcmNsZSBjeD0nNicgY3k9JzguMicgcj0nLjUnIGZpbGw9JyNGRkZGRkYnIHN0cm9rZT0nbm9uZScvPjwvc3ZnPg==);
    background-repeat: no-repeat;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
    background-position: right calc(.375em + .1875rem) center;
}

input[type=date].validError {
    background-position: right calc(.375em + 2.5rem) center !important;
}

select.validError {
    background-position: right calc(.375em + 0.9rem) center !important;
}


/*add ok na validação de formulario*/

.validOk {
    color: #424E66;
    background-color: #FFFFFF;
    border: 2px solid #28a745 !important;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(209, 236, 255);
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSc4JyBoZWlnaHQ9JzgnIHZpZXdCb3g9JzAgMCA4IDgnPjxwYXRoIGZpbGw9JyMyOGE3NDUnIGQ9J00yLjMgNi43M0wuNiA0LjUzYy0uNC0xLjA0LjQ2LTEuNCAxLjEtLjhsMS4xIDEuNCAzLjQtMy44Yy42LS42MyAxLjYtLjI3IDEuMi43bC00IDQuNmMtLjQzLjUtLjguNC0xLjEuMXonLz48L3N2Zz4=);
    background-repeat: no-repeat;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
    background-position: right calc(.375em + .1875rem) center;
}

input[type=date].validOk {
    background-position: right calc(.375em + 2.5rem) center !important;
}

select.validOk {
    background-position: right calc(.375em + 0.9rem) center !important;
}


.validOkDocumento {
    border: 2px dashed #28a745 !important;
}

.validErrorDocumento {
    border: 2px dashed #DC393A !important;
}

.ImagemInicio {
    display: block;
    margin: 15px auto 10px;
    height: 80px;
    width: auto;
    max-width: 200px;
    object-fit: contain;
}

.TextoInicioSecre {
    font-size:50px;
    padding-bottom:20px;
    font-weight:bold;
    color: var(--primary-color-background);
    text-shadow: #cccccc 3px 2px
}

.dropdown-menu.show {
    max-height: 225px !important;
    overflow-y: overlay;
    border-radius: 8px;
    color: var(--primary-color) !important;
}

/* Css paginas Matricula */

.panelPag {
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    background-color: #ffffff;
    margin-top: 15px;
    min-height: 400px;
    padding: 0px;
    box-shadow: 5px 10px 8px #B4B4B4;
    margin-left: -10px;
    border-top-left-radius: 0.25rem;
}

.iconeAzul {
    color: var(--primary-color-background);
}

.NomeAlunoM {
    color: var(--primary-color-background);
    line-height: 1.1;
}

.GridTotal {
    font-size:12px;
    text-align:right;
    padding: 7px;
}

.menuHome {
    background: url(../img/icones/ic-dashboard@2x.svg) no-repeat top left;
    background-size: contain;
    border: none;
    width: inherit;
    height: inherit;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

.menuCadastro {
    background: url(../img/icones/ic-recruitment@2x.svg) no-repeat top left;
    background-size: contain;
    border: none;
    width: inherit;
    height: inherit;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

.menuCadastroActive {
    background: url(../img/icones/ic-recruitment-3@2xactive.svg) no-repeat top left;
    background-size: contain;
    border: none;
    width: inherit;
    height: inherit;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}


.menuSecretaria {
    background: url(../img/icones/ic-department@2xActive.svg) no-repeat top left;
    background-size: contain;
    border: none;
    width: inherit;
    height: inherit;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

.menuSecretariaActive {
    background: url(../img/icones/ic-department@2x.svg) no-repeat top left;
    background-size: contain;
    border: none;
    width: inherit;
    height: inherit;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

.container-fluid {
    padding-left: 0px !important;
}

.ddlFundoBranco {
    border-radius: 8px;
    border: 1px solid var(--primary-color);
    color: var(--primary-color) !important;
}

.ddlFundoBranco2 {
    border-radius: 8px;
    border: 1px solid #DCDCDC;
    color: black !important;
}


.ddlFundoBranco2:hover {
    border-radius: 8px;
    border: 1px solid var(--primary-color);
    color: black !important;
}

.ddlFundoBranco2 > a {
    color: black !important;
}

.ddlFundoBranco2 > a:focus {
    color: var(--primary-color) !important;
}


.ddlPadding0 > a {
    padding: 0px !important;
}

.ddlFundoBrancoSemBorda {
    width: -webkit-fill-available;
}

.ddlFundoBrancoSemBorda > a {
    border-radius: 8px;
    border: 0px;
    color: #999 !important;
    background-color: #ffffff;
    padding:0px !important;
}

.btn {
    font-family: Segoe UI !important;
    font-weight: 400;
    font-size: 18px !important;
    border-radius: 6px;
}

.btn-link {

    font-size: 14px !important;
    color: white !important;
    background-color: transparent !important;
}

    .btn-link:hover {

        background-color: transparent !important;
        border-radius: 8px 0px 0px 8px;
        font-size: 14px !important;
        color: white !important;
    }
li.active {
    font-weight: 400 !important;
    background-color: transparent !important;
    border: 0px !important;
    font-size: 14px !important;
    color: white !important;
}

button {
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s;
}

.sideBarSecretaria{
    text-align: center;
    color: #ffffff;
}
.root{
    background-color: white;
    max-height:100% !important;
}

.fundoCinza {
    background-color: #F0F0F0;
}

.table-responsive {
    height: 100%;
    min-height: 300px;
    max-height: 600px;
    overflow-y: auto;
    position: relative;
    border-radius: 8px;
    padding-bottom: 200px;
}

.table-responsive::-webkit-scrollbar {
    width: 10px;
}

.table-responsive::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}

.table-responsive::-webkit-scrollbar-thumb {
    background-color: var(--primary-color);
    outline: 1px solid slategrey;
    border-radius: 8px;
}

.tableStyle thead {
    background-color: var(--primary-color-disable);
    color: white;
    position: sticky;
    top: 0;
    z-index: 1;
}

.tableStyle thead > tr > th {
    color: white;
    font-weight: bold;
    text-align: center;
    vertical-align: initial;
}

.tableStyle > tbody > tr > td {
    color: var(--primary-color-disable);
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    max-width: 180px;
    text-overflow: ellipsis;
}
.tableStyle > tbody > tr > td.overflow {
    position: relative;
}
.tableStyle > tbody > tr > td .icon {
    display: none;
}
.tableStyle > tbody > tr > td .icon svg{
    width: 18px;
    position: absolute;
}
.tableStyle > tbody > tr > td.overflow .icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
}
.tableStyle > tbody > tr > td h6 {
    text-overflow: ellipsis;
    overflow: hidden;
}
.tableStyle > tbody > tr.trSelected > td {
    color: #027893;
}

.tableStyle > tbody > tr:nth-child(even) > td {
    background-color: #F0F0F0;
}

.tableStyle > tbody > tr:nth-child(odd) > td {
    background-color: #ffffff;
}

.tableStyle > tbody > tr.trSelected:nth-child(odd) > td,
.tableStyle > tbody > tr.trSelected:nth-child(even) > td {
    background-color: #cae9ef;
}

.txtBorder {
    border: 1px solid #DCDCDC;
}

    .txtBorder:focus {
        border: 1px solid var(--primary-color) !important;
        color: var(--primary-color);
    }

.ddlFundoBrancoBanco {
    border-radius: 8px;
    border: 1px solid #DCDCDC;
    width: 500px;
    height: 35px;
}
    .ddlFundoBrancoBanco:focus {
        border: 1px solid var(--primary-color) !important;
        color: var(--primary-color);
    }

.txtBorderMetodo {
    border-radius: 8px;
    border: 1px solid #DCDCDC;
}

    .txtBorderMetodo:focus {
        border: 1px solid var(--primary-color) !important;
        color: var(--primary-color);
    }

.formatarTable {
    width: 100%;
    overflow-x: scroll !important;
}

.formatarFonteTable {
    font-size: 9px;
}
.TextFieldMui{
    box-shadow: none !important;
}