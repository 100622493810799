.seb-key-container {
    background: #333;
    text-align: center;
    color: #eee;
    border-radius: 0.3em;
    margin: 0.2em;
    padding: 0.2em;
    width: 3.3em;
    height: 100%;
    box-sizing: border-box;
    cursor: pointer;
    -webkit-user-select: none;
    border: 1px solid #444;
    box-shadow: 0 0.2em 0 0.05em #222;
    border-bottom-color: #555;
}

.seb-key-container-white {
    background: #eee;
    color: #333;
    border: 1px solid #ccc;
    box-shadow: 0 0.2em 0 0.05em #aaa;
    border-bottom-color: #ddd;
}

.seb-key-container.seb-key-container-active {
    background: #2a2a2a;
    color: #aaa;
    position: relative;
    top: 0.2em;
    box-shadow: 0 0 0 0.05em black;
}

.seb-key-container.seb-key-container-active.seb-key-container-white {
    background: #ccc;
    color: #333;
    box-shadow: 0 0 0 0.05em #aaa;
}